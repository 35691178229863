import React from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

// Register components
ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend
);

const LineChartSingle = (props) => {
    const { data, labels, label, tooltip } = props
    // console.log("data", labels, label, data)
    // Chart data
    const chartData = {
        labels: label,
        datasets: [
            {
                label: labels[0],
                data: data,
                borderColor: "green",
                backgroundColor: "rgba(0, 255, 0, 0.2)",
                borderWidth: 2,
                fill: false,
                tension: 0.1,
                pointRadius: 0
            }
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        const index = tooltipItems[0].dataIndex;
                        return tooltip[index];
                    },
                },
            },
        },
        elements: {
            point: {
              radius: 5,          // Default dot size
              hitRadius: 10,      // Expands hover detection area
              hoverRadius: 8,     // Increases dot size when hovered
              hoverBorderWidth: 3 // Adds a border on hover for visibility
            }
          },
        scales: {
            x: {
                title: {
                    display: false,
                    text: "",
                },
                grid: { display: false, drawBorder: false },
                ticks: { display: false },
            },
            y: {
                grid: { display: false, drawBorder: false },
                ticks: { display: false },
                title: {
                    display: false,
                    text: "",
                },
            },
        },
    };

    return <Line data={chartData} options={options} />;
};

export default LineChartSingle;
