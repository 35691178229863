import React, { useRef, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const HorizontalBarChart = ({ labels, data, label }) => {
  const chartRef = useRef(null);
  const [gradientColors, setGradientColors] = useState([]);

  // Labels and Random Data
  // const labels = Array.from({ length: 20 }, (_, i) => `Location_${i + 1}`);
  // const dataValues = labels.map(() => Math.floor(Math.random() * 1000));

  useEffect(() => {
    if (!chartRef.current || gradientColors.length > 0) return; // Prevent re-renders

    const chart = chartRef.current.ctx;
    const gradients = data.map((_, index) => {
      const gradient = chart.createLinearGradient(0, 0, 1000, 0);
      gradient.addColorStop(0, `hsl(${index * 10}, 70%, 40%)`);
      gradient.addColorStop(1, `hsl(${index * 10}, 70%, 60%)`);
      return gradient;
    });

    setGradientColors(gradients);
  }, [chartRef]); // Run once when component mounts

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: data,
        backgroundColor: gradientColors.length > 0 ? gradientColors : "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: { beginAtZero: true, grid: { display: false }, ticks: { display: false } },
      y: { beginAtZero: true },
    },
  };

  return (
    <div style={{ height: "400px" }}>
      <Bar ref={chartRef} data={chartData} options={options} />
    </div>
  );
};

export default HorizontalBarChart;
