import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import CurrencyFormat from 'react-currency-format';
import axios from 'axios';
import moment from 'moment/moment';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { formatDate } from '../../../../Config/formatDate';
import ResponsivePagination from 'react-responsive-pagination';
import { useSelector } from 'react-redux';


export default function SystemWallet() {
    const dataval = useSelector((state) => state.dataValue)
    const searchParams = new URLSearchParams(window.location.search)
    const param = useParams()
    let navigate = useNavigate()
    const location = useLocation()
    const [walletBalance, setwalletBalance] = useState("")
    const [transactionHistory, settransactionHistory] = useState()
    const [loading, setloading] = useState(false)
    const [popData, setPopData] = useState({})
    const [show, setShow] = useState(false);
    const [errors, seterrors] = useState(false)
    const [modalType, setmodalType] = useState("")
    const [loadingBtn, setloadingBtn] = useState(false)
    const [popValue, setpopValue] = useState({
        amount: ""
    })
    const [count, setCount] = useState(1)
    let limit = 20
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
        type: searchParams.get("type") || "",
        search: searchParams.get("search") || "",
    })
    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }


    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        seterrors(false)
        setpopValue({
            amount: ""
        })

    }
    const handleChange = (e) => {
        setpopValue({ ...popValue, [e.target.name]: e.target.value })
    }


    const getWalletBalance = () => {
        Api.GetApi(endPoints.GETSYSTEMWALLET).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                setloading(false)
                setwalletBalance(res.data.data.balance)
                // setwalletBalance()
            }
        })
    }
    const getTransactionHistory = (param) => {
        Api.GetApi(`${endPoints.GETWALLETTRANSACTION}/${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {

                if (res.data.data.length > 0) {
                    let newCount = Math.ceil(parseInt(res.data.data[0].totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }


                setloading(false)
                console.log(res?.data?.data)
                settransactionHistory(res?.data?.data[0]?.result)
            }
        })
    }

    const walletAction = () => {
        setmodalType("walletAction")
        setShow(true)
    }

    const submitWalletAction = () => {
        if (popValue.amount === "" || popValue.amount <= "0") {
            seterrors(true)
        } else {
            setloadingBtn(true)
            seterrors(false)
            let data = {}
            data = {
                amount: parseFloat(popValue.amount),
            }
            updateWalletAction(data)
            setloadingBtn(false)
            // ADDWALLETCREDIT 
        }
    }


    const updateWalletAction = (data) => {
        Api.PostApi(endPoints.ADDWALLETCREDIT, data).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                Api.Alert(res.data.data, "success")
                handleClose()
                setloading(false)
                getWalletBalance()
            }
        })

    }

    useEffect(() => {
        setParams({ ...params, page: searchParams.get("page") || 1 })
    }, [searchParams.get("page")])

    const setCurrentPage = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        } else {
            setparam("page", number)
            setParams({ ...params, page: number })
        }
    }
    useEffect(() => {
        setParams({ ...params, page: searchParams.get("page") || 1 })
    }, [searchParams.get("page")])


    useEffect(() => {
        setparam("page", params.page)
        getTransactionHistory(location.search)
    }, [location.search])

    useEffect(() => {
        getWalletBalance()
    }, [])


    return (

        <main id="main" className="main">
            <div className='d-flex align-items-center justify-content-between mb-md-3'>
                <div className="pagetitle">
                    <h1>Admin Wallet</h1>
                </div>
                <div className='d-flex align-items-center'>
                    <h5 className='mb-0'>
                        <Icon.Wallet className="me-2" />
                        <CurrencyFormat className={walletBalance && parseFloat(walletBalance).toFixed(2) < 0 ? 'text-danger' : ""}
                            value={walletBalance ? parseFloat(walletBalance).toFixed(2) : "0"} displayType={'text'} thousandSeparator={true} prefix={'RM '}
                        />
                    </h5>
                    {dataval?.isSupport ?
                        null :
                        <Button variant="primary" size='sm' className='ms-2 align-items-center' onClick={walletAction} >Add Credits</Button>
                    }
                </div>
            </div>
            {loading ?
                <div className="loader-line"></div> :
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">

                                {transactionHistory && transactionHistory.length > 0 ? <>
                                    <div className="table-responsive">
                                        <table className="table dataTable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th className='sorting' scope="col">Transaction  Id</th>
                                                    <th scope="col">Date </th>
                                                    <th scope="col">User </th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Payment Type</th>
                                                    <th scope="col">Payment Against</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {transactionHistory.map((data, index) => {

                                                    let updInd = index + 1
                                                    return (
                                                        <tr key={index}>
                                                            <td>{(params.page - 1) * 20 + updInd}</td>
                                                            {/* <td>{updInd}</td> */}
                                                            <td>{data?.transaction_id}</td>
                                                            <td className='text-capitalized'>{formatDate(data?.createdAt)}</td>
                                                            <td className=''>
                                                                {data?.user.length > 0 ? <Link to={`/users/${data?.user[0]?._id}`} >
                                                                    <span style={{ color: "#000" }}> {data?.user[0]?.first_name} {data?.user[0]?.last_name}</span>
                                                                    <br />
                                                                    {data?.user[0]?.email}
                                                                </Link> : "--"}
                                                            </td>

                                                            <td>
                                                                <CurrencyFormat value={parseFloat(data?.amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                                            </td>
                                                            <td className='text-capitalized'>{data?.payment_type}</td>
                                                            <td className='text-capitalized'>{data?.payment_against.split("_").join(" ")}</td>

                                                            <td className='text-capitalized'>
                                                                <span className={data?.status === "succeeded" || data?.status === "succeed" ? 'badge bg-success' : "badge bg-danger"}>
                                                                    {data?.status === "succeeded" || data?.status === "succeed" ? "Success" : data?.status}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )


                                                })}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div style={{ maxWidth: "500px" }}>
                                        <ResponsivePagination
                                            current={parseFloat(searchParams.get("page"))}
                                            total={count.length}
                                            onPageChange={setCurrentPage}
                                            disabled={params.page === count.length}
                                            // maxWidth={500}
                                            className={"pagination "}
                                        />
                                    </div>

                                </>
                                    :
                                    <>
                                        <div className='text-center py-3'>
                                            <h3>No transaction history found</h3>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>}

            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title> Add balance</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row'>
                        <div className='col-12'>
                            <Form.Group className="mb-3" >
                                <Form.Label><strong>Amount*</strong></Form.Label>
                                <Form.Control type="number" name="amount" placeholder="0.00" maxLength="10" onChange={handleChange}
                                    value={popValue.amount}
                                    isInvalid={errors && popValue?.amount == ""}
                                    min="0"
                                    step="1"
                                />
                                {errors && popValue?.amount == "" ? <Form.Control.Feedback type="invalid" className='d-block'>Please enter amount</Form.Control.Feedback> : null}
                                {errors && popValue?.amount == "0" || errors && popValue?.amount < "0" ? <Form.Control.Feedback type="invalid" className='d-block'>Amount should be more than 0</Form.Control.Feedback> : null}
                            </Form.Group>
                        </div>
                    </div>




                    <div className="text-end mt-2" >
                        <button disabled={loadingBtn} className='btn btn-primary btn-sm me-3' onClick={submitWalletAction} > Confirm</button>
                        <button className='btn btn-outline-danger btn-sm' onClick={handleClose} > Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>
        </main>
    )
}
