import React from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

// Register components
ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend
);

const LineChart = (props) => {
    const { data, chart, labels } = props
    console.log("labels123", data)

    // Chart data
    const chartData = {
        labels: labels,
        datasets: chart,
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "bottom",
            },
        },
        elements: {
            point: {
                radius: 5,          // Default dot size
                hitRadius: 10,      // Expands hover detection area
                hoverRadius: 8,     // Increases dot size when hovered
                hoverBorderWidth: 3 // Adds a border on hover for visibility
            }
        },
        scales: {
            x: {
                title: {
                    display: false,
                    text: "",
                },
                ticks: {
                    maxTicksLimit: 12, // Limit number of dates shown
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Number of users",
                },
            },
        },
    };

    return <Line data={chartData} options={options} />;
};

export default LineChart;
