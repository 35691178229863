import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from "chart.js";

// Register chart components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StackedBarChart = (props) => {
    const { data, stack, labels, tooltip, legend, position } = props
    console.log("first sdfdsf", data, labels)
    const chartData = {
        labels: labels,
        datasets: data,
    };

    const options = {
        responsive: true,
        elements: {
            point: {
              radius: 5,          // Default dot size
              hitRadius: 10,      // Expands hover detection area
              hoverRadius: 8,     // Increases dot size when hovered
              hoverBorderWidth: 3 // Adds a border on hover for visibility
            }
          },
        plugins: {
            legend: { display: legend, position: position || "top" },
            tooltip: {
                enabled: true,
                callbacks: {
                    title: (tooltipItems) => {
                        const index = tooltipItems[0].dataIndex;
                        return tooltip[index]
                    },
                },
            },
        },
        scales: {
            x: {
                stacked: stack, // Enable stacked bars on X-axis
                grid: { display: false, drawBorder: false },
                ticks: { display: false },
                title: {
                    display: false,
                    text: "",
                },
            },
            y: {
                stacked: stack, // Enable stacked bars on Y-axis
                ticks: { beginAtZero: true },
            },
        },
    };

    return <Bar data={chartData} options={options} />;
};

export default StackedBarChart;
