import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, Tooltip, Title } from "chart.js";
import { BoxPlotController, BoxAndWiskers } from "@sgratzl/chartjs-chart-boxplot";
import { Chart } from "react-chartjs-2";

// ✅ Register required components
ChartJS.register(BoxPlotController, BoxAndWiskers, CategoryScale, LinearScale, Tooltip, Title);


export default function BoxPlotChart({data, labels, tooltip}) {
    console.log("box data", data)
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "",
          data: data,
          borderColor: "black",
          backgroundColor: "rgba(255, 99, 132, 1)"
        }
      ]
    };
    
    const options = {
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: "Box Plot Example"
        },
        tooltip: {
          enabled: true,
          callbacks: {
              title: (tooltipItems) => {
                  const index = tooltipItems[0].dataIndex;
                  return tooltip[index]
              },
          },
      },
      },
      scales: {
        x: { grid: { display: false }, ticks: { display: false } }
    },
    };
  return <Chart type="boxplot" data={chartData} options={options} />;
}
