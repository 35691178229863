import React, { useEffect, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment/moment';
import Index from '../../../../Assets/Images/Index'
import Modal from 'react-bootstrap/Modal';
import CurrencyFormat from 'react-currency-format';
import { Pagination } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import _ from "underscore";
import jsPDF from 'jspdf';
import QRPDF from './QRPDF';
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';

{/* 'online', 'offline', 'temporary_offline', 'under_maintenance', 'coming_soon' */ }
const chargerstatus = [
    { name: "Online", value: "online" },
    { name: "Offline", value: "temporary_offline" },
    { name: "Under maintenance", value: "under_maintenance" },
    { name: "Coming soon", value: "coming_soon" },
]
const parkingValues = [
    { name: "Paid Parking", value: "paid" },
    { name: "Free Parking", value: "free" },
]

export default function ChargingStation() {
    const dataval = useSelector((state) => state.dataValue)
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setloading] = useState(true)
    const [chargingStations, setchargingStations] = useState({})
    const [sessionCloseId, setsessionCloseId] = useState()
    const [showFilterBtn, setshowFilterBtn] = useState(false);
    const [popDataMain, setPopDataMain] = useState({})
    const [popData, setPopData] = useState({})
    const [modalType, setmodalType] = useState({})
    const [show, setShow] = useState(false);
    const [search, setsearch] = useState({
        location: "",
        charger: ""
    })
    let limit = 20
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
        location: searchParams.get("location") || "",
        charger: searchParams.get("charger") || "",
    })
    const [count, setCount] = useState(1)
    const [stationPrefrences, setstationPrefrences] = useState({
        location_id: "",
        status: "",
        paid_parking: "free"
    })
    const [errors, setErrors] = useState(false)
    const [chargerProfile, setchargerProfile] = useState({
        charger_id: "",
        description: "",
        priceEdit: false,
        descriptionEdit: false,
        price: {
            price: "",
            power: "",
            unit: "",
        },
    })

    const handleInputchange = (e) => {
        if (e.target.name === "description") {
            setchargerProfile({ ...chargerProfile, [e.target.name]: e.target.value })

        } else {
            setchargerProfile({ ...chargerProfile, price: { ...chargerProfile.price, [e.target.name]: e.target.value } })
        }
        console.log("chargerProfile", chargerProfile)
    }

    const toggleEditState = (tar, val) => {
        setchargerProfile({ ...chargerProfile, [tar]: val })
    }
    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }

    const handleClose = () => {
        setShow(false)
        setmodalType({})
        setPopData({})
        setPopDataMain({})
        setchargerProfile({
            charger_id: "",
            description: "",
            priceEdit: false,
            descriptionEdit: false,
            price: {
                price: "",
                power: "",
                unit: "",
            },
        })
    };
    const handleShow = () => setShow(true);

    const getchargingStations = (param) => {
        Api.GetApi(`${endPoints.CHARGING_STATION}${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                // console.log("res.data.", res)

                let arr = []
                res.data.data && res.data.data.length > 0 && res.data.data.map((opt) => {
                    var a = moment(opt?.metrics?.chargingStart);
                    var b = moment(opt?.metrics?.chargingStop);
                    var duration = moment.duration(b.diff(a));
                    duration.hours()
                    duration.minutes()
                    duration.seconds()
                    let duration1 = `${duration.hours() > 0 ? `${duration.hours()}hr` : ""} 
                    ${duration.minutes() > 0 ? `${duration.minutes()}min ` : ""}       ${duration.seconds() > 0 ? `${duration.seconds()}s` : ""}`
                    arr.push({ ...opt, duration: duration1 })
                })
                if (res.data.data.length > 0) {
                    let newCount = Math.ceil(parseInt(res.data.data[0]?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }

                setchargingStations(arr)
                setloading(false)
            }
        })
    }

    const searchUser = (e) => {
        setshowFilterBtn(true)
        setsearch({ ...search, [e.target.name]: e.target.value })
        if (e.target.value !== "") {
            setparam([e.target.name], e.target.value)
        } else {
            deleteparam("location")
            deleteparam("charger")
        }
    }
    const clearFilter = () => {
        setshowFilterBtn(false)
        setsearch({
            location: "",
            charger: "",
        })
        setParams({ ...params, 'charger': "" })
        setParams({ ...params, 'location': "" })
        deleteparam("location")
        deleteparam("charger")
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.charger != "") {
                setparam('charger', search.charger)
                setParams({ ...params, 'charger': search.charger })
            }
            if (search.location != "") {
                setparam('location', search.location)
                setParams({ ...params, 'location': search.location })
            }
            if (search.charger === "" && search.location === "") {
                setParams({ ...params, 'charger': "", 'location': "" })
                // setParams({ ...params, 'location': "" })
                deleteparam("location")
                deleteparam("charger")
            }
            // DashData(`?search=${params.search}`)
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [search])

    const prevPage = () => {
        setloading(true)
        setparam("page", Number(params.page) - 1)
        setParams({ ...params, page: Number(params.page) - 1 })
    }
    const nextPage = () => {
        setloading(true)
        setparam("page", Number(params.page) + 1)

        setParams({ ...params, page: Number(params.page) + 1 })
    }

    const pageChange = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        } else {
            setparam("page", number)
            setParams({ ...params, page: number })
        }
    }

    const showModal = (main, data, type) => {
        // console.log("----", main, data, type)
        console.log("data", data.chargerProfile)
        setPopData(data)
        setPopDataMain(main)
        setmodalType(type)
        setShow(true)
        if (type == "updateLocation") {
            setstationPrefrences({
                location_id: data?._id,
                status: data?.status ? data?.status : "online",
                paid_parking: data?.paid_parking ? "paid" : "free"
            })
        }
        if (type == "viewChargerProfile") {
            console.log("pop-data", data)
            setchargerProfile({
                charger_id: data?._id,
                description: data?.charger_profile?.description?.description,
                price: {
                    price: data?.charger_profile?.price?.price,
                    power: data?.charger_profile?.price?.power,
                    unit: data?.charger_profile?.price?.unit,
                },
            })

        }
    }

    const downloadQR = (data) => {
        var l = {
            orientation: 'p',
            unit: 'pt',
            format: 'a4',
            compress: true,
            autoSize: true,
            printHeaders: true
        }
        const doc = new jsPDF(l, '', '', '')
        const htmlToConvert = ReactDOMServer.renderToString(<QRPDF data={data} />)
        // setallData(htmlToConvert)
        doc.html(htmlToConvert, {
            callback: function (doc) {
                doc.save(`CS_QR_${data?.ev_charging_station_id}.pdf`);
            }
        });
    }
    // 
    const [feedbackModal, setFeedbackModal] = useState(false);
    const closefeedbackModal = () => setFeedbackModal(false);
    const showfeedbackModal = () => setFeedbackModal(true);

    const [feedbackImages, setfeedbackImages] = useState({
        main: "",
        list: [],
    })
    const showFeedbackImages = (main, list) => {
        setfeedbackImages({ main: main, list: list })
        // showModal(feedbackImages, "feedbackimages")
        showfeedbackModal()
    }
    const changfdbckMainImg = (newimg) => {
        setfeedbackImages({ ...feedbackImages, main: newimg })
    }
    // const [file, setFile] = useState(null)
    const [preview, setpreview] = useState(null)
    const handleFileChange = (event, id, listLength) => {

        const validFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxSize = 5 * 1024 * 1024; // 5MB
        const selectedImages = [];
        let maxUploadsAllowed = 5
        let updatedate = []

        maxUploadsAllowed = maxUploadsAllowed - listLength
        const handleImageLoad = (file, sendData) => {
            if (file.width > 599 && file.height > 179) {

                if (selectedImages.length < maxUploadsAllowed) {
                    selectedImages.push(file);
                    updatedate.push(sendData);

                } else {
                    Api.Alert(`You can only select up to ${maxUploadsAllowed} images.`, "error");
                }
                if (selectedImages.length === event.target.files.length) {
                    let data = new FormData();
                    if (updatedate.length > 0) {
                        for (let i = 0; i < updatedate.length; i++) {
                            data.append("location_images", updatedate[i])
                        }
                    }
                    data.append("locationId", id)
                    Api.PutApi(endPoints.UPLOADLOGO, data).then(res => {
                        if (res.error === true) {
                            Api.Alert(res.response.data.error, "error")
                        } else {
                            if (res.data.data) {
                                let resData = res.data
                                Api.Alert(resData.data, "success")
                                getchargingStations("")
                            }
                        }
                    })

                }
            } else {
                Api.Alert("Images size should be 600 X 180", "error");
            }
        };
        for (let i = 0; i < event.target.files.length && selectedImages.length < 5; i++) {
            const file = event.target.files[i];

            if (!validFileTypes.includes(file.type)) {
                Api.Alert("Please select a valid image file (JPEG, PNG, or GIF).", "error")
                continue;
            }
            if (file.size > maxSize) {
                Api.Alert("Please select an image file smaller than 5MB.", "error")
                continue;
            }

            let reader = new FileReader();

            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result; // Use the result of the FileReader
                img.onload = () => {
                    handleImageLoad(img, file);
                };
            };

            if (!_.isUndefined(file)) {
                reader.readAsDataURL(file);
            }
        }


    }
    const removestationLogo = (id) => {
        let data = {}
        data.image_id = id
        // console.log("id: ", data)
        // console.log("typeof data ", typeof data)
        // console.log("typeof data.image_id ", typeof data.image_id)

        Api.DeleteApi(`${endPoints.REMOVE_LOGO}/${id}`).then(res => {

            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res.data.data) {
                    let resData = res.data
                    Api.Alert(resData.data, "success")
                    getchargingStations("")
                    handleClose()
                }
            }
        })

    }
    const locationStatusChange = (e) => {
        setstationPrefrences({ ...stationPrefrences, [e.target.name]: e.target.value })
    }
    const updateLocationstatus = () => {

        if (stationPrefrences.status === "") {
            setErrors(true)
        } else {
            setErrors(false)

            let data = {
                location_id: stationPrefrences.location_id,
                status: stationPrefrences.status,
                paid_parking: stationPrefrences.paid_parking === "paid" ? true : false
            }
            console.log(data)

            Api.PutApi(endPoints.UPDATELOCATIONSTATUS, data).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    if (res.data.data) {
                        let resData = res.data
                        Api.Alert(resData.data, "success")
                        getchargingStations("")
                        setstationPrefrences({
                            location_id: "",
                            status: "",
                            paid_parking: "free"
                        })
                        handleClose()

                    }
                }
            })

        }


    }

    const updatePricing = () => {
        if (chargerProfile.price.price === "" || chargerProfile.price.power === "" || chargerProfile.price.unit === "") {
            setErrors(true)
        } else {
            setErrors(false)
            let data = {
                power: chargerProfile.price.power,
                price: chargerProfile.price.price,
                unit: chargerProfile.price.unit,
                charger_id: chargerProfile.charger_id
            }
            Api.PostApi(endPoints.CHARGING_STATION_UPDATE_PRICE, data).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    if (res.data.data) {
                        let resData = res.data


                        Api.Alert(resData.data, "success")
                        toggleEditState("priceEdit", false)
                        getchargingStations("")
                        handleClose()

                    }
                }
            })
        }
    }
    const updatedescription = () => {

        console.log("chargerProfile", chargerProfile)
        if (chargerProfile.description === "") {
            setErrors(true)
            console.log("errors", errors)
        } else {
            setErrors(false)
            let data = {
                description: chargerProfile.description,
                charger_id: chargerProfile.charger_id
            }
            Api.PostApi(endPoints.CHARGING_STATION_UPDATE_DESCRIPTION, data).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    if (res.data.data) {
                        let resData = res.data


                        Api.Alert(resData.data, "success")
                        toggleEditState("description", false)
                        getchargingStations("")
                        handleClose()

                    }
                }
            })
        }
    }


    useEffect(() => {
        // search.location !== "" || search.charger
        if (searchParams.get("location") || searchParams.get("charger")) {

            setshowFilterBtn(true)
        } else {
            setshowFilterBtn(false)
            clearFilter()
        }
        getchargingStations(location.search)
    }, [location.search])



    // console.log("chargingStations", chargingStations)
    // console.log("search--", search)
    // console.log("searchParams.get(location)", searchParams.get("location"))
    // console.log("searchParams.get(charger)", searchParams.get("charger"))
    // console.log("popData", popData)
    // console.log("stationPrefrences", stationPrefrences)
    return (
        <>

            {loading ?
                <div className="loader-line"></div> :
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Charging Stations</h1>
                    </div>
                    <div className="card">
                        <div className="card-body ">
                            <div className="mb-0 mb-md-4 userdetailTopRow">
                                <div className="search-bar  mb-3 mb-md-0 chargeSearchBox d-flex">
                                    <div className="search-form d-flex align-items-center" >
                                        <input type="text" className='form-control' name="location" placeholder="Search by location" value={search.location} title="Enter search keyword" onChange={searchUser} />
                                        <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                    </div>
                                    <div className="search-form d-flex align-items-center" >
                                        <input type="text" className='form-control' name="charger" placeholder="Search by charging station" value={search.charger} title="Enter search keyword" onChange={searchUser} />
                                        <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                    </div>
                                    {showFilterBtn ?
                                        <button type="button" className="textClear" onClick={clearFilter}>Clear Search</button> : null}
                                </div>
                            </div>
                            {chargingStations && chargingStations.length > 0 ?
                                <>
                                    {chargingStations.length > 0 && chargingStations.map((data, index) => {
                                        // console.log("data -", data)
                                        let updInd = index + 1
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="card charginStationCard">
                                                    <div className="card-body pb-0 pt-4 ">
                                                        <div className="card-header pt-0 px-0">
                                                            <div className="row mb-2 align-items-center ">
                                                                <div className="col-md-6">
                                                                    <div className='chargeStationTitle d-flex'>
                                                                        {/* <div className='chargeStationTitle__icon'>
                                                                          <Icon.GeoAlt />
                                                                        </div> */}
                                                                        <button className='chargeStationTitle__icon d-flex'
                                                                            onClick={() => !dataval?.isSupport && showModal("", data, "updateLocation")}
                                                                            title={data.status ? data.status.split("_").join(" ") : "Online"}>
                                                                            {dataval?.isSupport ? null :
                                                                                <span className='chargeStationTitle__btn'>  <Icon.PencilFill /></span>
                                                                            }
                                                                            {data.status ? <img src={Index[data.status]} alt={data.status} /> :
                                                                                <img src={Index.online} alt="Online" />
                                                                            }
                                                                        </button>
                                                                        <div className='chargeStationTitle__cntnt'>
                                                                            <div> <strong> {data?.operator_name}</strong> </div>
                                                                            <div>
                                                                                {data?.address?.city}{data?.address?.state ? `, ${data?.address?.state}` : ""}{data?.address?.postalCode ? `, ${data?.address?.postalCode}` : ""}{data?.address?.country ? `, ${data?.address?.country}` : ""}
                                                                            </div>
                                                                            <button className='btn p-0' onClick={() => !dataval?.isSupport && showModal("", data, "updateLocation")}>
                                                                                <strong> Parking:</strong> <span className='position-relative d-inline-block pe-4'>
                                                                                    {data?.paid_parking ? data?.paid_parking == true ? "Paid" : "Free" : "Free"}
                                                                                    {dataval?.isSupport ?
                                                                                        null :
                                                                                        <span className='chargeStationTitle__btn'>  <Icon.PencilFill /></span>
                                                                                    }
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mt-2 mt-md-0 ">
                                                                    {data?.locationimages && data?.locationimages.length > 0 ?
                                                                        <>
                                                                            <div className='chargeStationTitle d-flex imageContainer'>

                                                                                <div className='chargeStationTitle__cntnt'>
                                                                                    <div className='logoUpdateBox'>
                                                                                        <div className='logoUpdateBox__img logoUpdateBox__grid'>
                                                                                            {data?.locationimages.map((locimg, loindin) => {
                                                                                                return (
                                                                                                    <div className='logoUpdateBox__Box' key={loindin}>
                                                                                                        {dataval?.isSupport ? null :
                                                                                                            <button onClick={() => showModal("", locimg?._id, "deleteStationimg")} className="deleteBtn btn btn-danger btn-sm" title="Remove image">
                                                                                                                <Icon.Trash />
                                                                                                            </button>
                                                                                                        }
                                                                                                        <img src={locimg.url} alt="" />
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                            {data?.locationimages.length < 5 && !dataval?.isSupport ?
                                                                                                <div className='logoUpdateBox__Box logoUpdateBox__btnHolder d-flex'>
                                                                                                    <label className="uploadBtn btn btn-primary btn-sm" title="Upload new image">
                                                                                                        <Icon.Camera />
                                                                                                        <input type='file' multiple className="d-none" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleFileChange(e, data?._id, data?.locationimages.length)} />
                                                                                                    </label>
                                                                                                    {/* <button onClick={() => showModal(data?._id, "deleteStationimg")} className="deleteBtn btn btn-danger btn-sm" title="Remove image">
                                                                                                <Icon.Trash />
                                                                                            </button> */}
                                                                                                </div> : null
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className='chargeStationTitle d-flex'>
                                                                                <div className='chargeStationTitle__icon'>
                                                                                    <Icon.Image />
                                                                                </div>
                                                                                {!dataval?.isSupport ?
                                                                                    <div className='chargeStationTitle__cntnt d-flex align-items-center'>
                                                                                        <div>
                                                                                            <label className="btn btn-outline-success btn-sm" title="Upload new profile image"><Icon.Upload />
                                                                                                <input type='file' multiple className="d-none" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleFileChange(e, data?._id, 0)} />
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className='d-flex flex-column'>
                                                                                            <small className='extrasmall small ms-2'>Minimum recomended size 600 X 180 </small>
                                                                                            <small className='extrasmall small ms-2'>Max 5 images allowed </small>
                                                                                        </div>
                                                                                    </div>
                                                                                    : null
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {data?.chargingstation && data?.chargingstation.length > 0 ?
                                                            <div className="table-responsive">
                                                                {/* v-center */}
                                                                <table className="table dataTable v-center hideLastBorder">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">End Point</th>
                                                                            <th scope="col">Connectors</th>
                                                                            {/* <th scope="col">End point</th> */}
                                                                            <th scope="col">Type</th>
                                                                            {/* <th scope="col">Image</th> */}
                                                                            <th scope="col">Feedback</th>
                                                                            <th scope="col">QR Code</th>
                                                                            <th scope="col">Charger Profile</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {data?.chargingstation.map((cntnt, ind) => {
                                                                            return (
                                                                                <tr key={ind}>
                                                                                    <td  >
                                                                                        <strong> {ind + 1}</strong>
                                                                                    </td>
                                                                                    <td>
                                                                                        <strong className={cntnt?.online ? "dot online" : ""} title={cntnt?.online ? "Online" : "Offline"}>{cntnt?.endpoint}</strong>
                                                                                        {/* <p style={{ color: "#666" }}>
                                                                                <small>{cntnt?.location?.address?.city}</small>
                                                                            </p> */}
                                                                                    </td>
                                                                                    <td >
                                                                                        <button className='link' onClick={() => !dataval?.isSupport && showModal(data, cntnt, "connector")}><strong>{cntnt.connectors && cntnt.connectors.length || 0}</strong></button>
                                                                                    </td>
                                                                                    {/* <td >
                                                                            {cntnt?.endpoint || "-"}
                                                                        </td> */}
                                                                                    <td>
                                                                                        <span className={`badge ${cntnt?.public ? "bg-secondary" : "bg-danger"}`}>{cntnt?.public ? "Public" : "Private"}</span>
                                                                                    </td>
                                                                                    {/* <td >                                                                            
                                                                        </td> */}

                                                                                    <td width={"15%"}>
                                                                                        {cntnt?.feedback != "" ?
                                                                                            <button className='btn btn-success btn-sm' onClick={() => showModal(data, cntnt, "feedback")}> View</button> : "No feedback"}
                                                                                    </td>
                                                                                    <td width={200}>
                                                                                        <button className='btn btn-success btn-sm' onClick={() =>  showModal(data, cntnt, "qr")}> View</button>
                                                                                        <button className='btn btn-outline-secondary btn-sm ms-2' onClick={() => downloadQR(cntnt)}> Download</button>
                                                                                    </td>
                                                                                    <td width={120} className='text-center'>
                                                                                        <button className="btn btn-sm btn-success" disabled={dataval?.isSupport} onClick={() => !dataval?.isSupport && showModal(data, cntnt, "viewChargerProfile")}>
                                                                                            View
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            :
                                                            <div className='text-center py-3'>
                                                                <h3>No result found</h3>
                                                            </div>}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                    {/* <Pagination>
                                        <Pagination.First onClick={prevPage} disabled={params.page == 1 ? true : false} />
                                        {count.length > 0 && count.map((number) => {
                                            return (
                                                <Pagination.Item onClick={() => pageChange(number)} key={number} active={number == params.page}>
                                                    {number}
                                                </Pagination.Item>
                                            )
                                        })}
                                        <Pagination.Last onClick={nextPage} disabled={params.page == count.length ? true : false} />
                                    </Pagination> */}
                                </> :
                                <div className='text-center py-3'>
                                    <h3>No result found</h3>
                                </div>
                            }
                        </div>
                    </div>

                    <Modal className='feedbakPop'
                        centered
                        show={show} onHide={handleClose}
                        size={modalType == "feedback" ? "xl" : modalType == "connector" ? "lg" : modalType == "feedbackimages" ? "lg" : modalType == "viewChargerProfile" ? "lg" : "md"}    >
                        {modalType == "feedbackimages" || modalType == "deleteStationimg" ? null :
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {modalType == "connector" ? `Connectors (${popData?.endpoint})` : modalType == "feedback" ? "Feedback" : modalType == "updateLocation" ? "Update Location" : modalType == "viewChargerProfile" ? "Charger Profile" : "QR Code"}
                                </Modal.Title>
                            </Modal.Header>}
                        <Modal.Body>
                            {modalType == "connector" &&
                                <>
                                    {!_.isEmpty(popData) &&
                                        <>
                                            <div className="alert p-0 fade show d-flex align-items-start" role="alert">
                                                <p className='me-5 d-flex'>
                                                    <strong className={popData?.online ? "dot online" : "dot offline"} title={popData?.online ? "Online" : "Offline"}>
                                                    </strong>
                                                    <span>
                                                        <strong>
                                                            {popDataMain?.operator_name}
                                                        </strong><br />
                                                        <small style={{ color: "#666" }}>{popDataMain?.address?.city}</small>
                                                    </span>
                                                </p>
                                                {/* <strong>
                                                    {popData?.endpoint}
                                                </strong> */}
                                                {/* <span className={`ms-3 badge ${popData?.active ? "bg-primary" : "bg-danger"}`}>{popData?.active ? "Available" : "Not available"}</span> */}
                                            </div>
                                            {popData?.connectors && popData.connectors.length > 0 ?
                                                <>
                                                    {popData.connectors.map((opt, i) => {
                                                        return (
                                                            <div className="card" key={i}>
                                                                <div className="card-header" style={{ backgroundColor: "#e7e7e7" }}>
                                                                    <strong>Connector {opt?.connectorId}</strong>
                                                                    <span className="ms-3 badge bg-primary">{opt?.status}</span>
                                                                </div>
                                                                <ul className="list-group list-group-flush">
                                                                    <li className="list-group-item">
                                                                        <strong>Power: </strong>
                                                                        <span>{opt?.power}kw ({opt?.power_type})</span>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <strong>Type: </strong>
                                                                        <span>{opt?.type}</span>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <strong>Format: </strong>
                                                                        <span>{opt?.format}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                                :
                                                <h6 className='py-5'>No connector found!</h6>
                                            }
                                        </>
                                    }
                                </>
                            }
                            {modalType == "qr" && !_.isEmpty(popData) &&
                                <>
                                    <div className='qr_code_outer  text-center'>
                                        <div className='qr_logo'>
                                            <img src={Index?.logo} alt="" width={200} />
                                        </div>
                                        <div className='qr_code_img_outer'>
                                            <img className="qr_code_img" src={popData?.qrcode?.Qrcode} alt="" width={200} />
                                        </div>
                                        <p className="ref"><strong>Charger ID: </strong>{popData?.qrcode?.reference_id}</p>
                                    </div>
                                    {/* <QRPDF data={popData} /> */}
                                    <div className='text-center my-3'>
                                        <button className='btn btn-secondary btn-sm ms-2' onClick={() => downloadQR(popData)}> Download</button>
                                    </div>
                                </>
                            }

                            {modalType == "feedback" && <>
                                <div className="table-responsive feedbackTable">
                                    <table className="table dataTable v-center">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">User</th>
                                                <th scope="col">Connector</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col" width="60">Date</th>
                                                <th scope="col">Feedback</th>
                                                <th scope="col">Rating</th>
                                                <th scope="col">Image</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {popData?.feedback.map((data, indx) => {
                                                const stars = [];
                                                for (let i = 1; i <= 5; i++) {
                                                    const starValue = i - 0.5;
                                                    const isHalfStar = data?.rating >= starValue && data?.rating < i;
                                                    const isFilledStar = data?.rating >= i;
                                                    stars.push(
                                                        isFilledStar ? "StarFill" : isHalfStar ? "StarHalf" : "Star"
                                                    );
                                                }
                                                return (
                                                    <tr key={indx}>
                                                        <td >{indx + 1}</td>
                                                        <td>
                                                            <div> {data?.user?.first_name} {data?.user?.last_name}</div>
                                                            <div> {data?.user?.email}</div>
                                                            <div> {data?.user?.phone_number}</div>
                                                        </td>
                                                        <td >
                                                            <div><small><strong>Id: </strong></small>  {data?.connector?.connectorId}</div>
                                                            <div><small><strong>Type:</strong></small>  {data?.connector?.type}</div>
                                                        </td>
                                                        <td> {data?.session?.cost?.currency.toUpperCase()} {data?.session?.cost?.amount.toFixed(2)}</td>
                                                        <td>{data?.createdAt ? moment(data.createdAt).format("ll") : "--"}</td>
                                                        <td className='feedbackText'><div >{data?.feedback}</div></td>
                                                        <td>
                                                            <div className='starRatingHolder'>

                                                                {stars.map((iconName, iconIndex) => {
                                                                    return <React.Fragment key={iconIndex}>
                                                                        {iconName === "StarFill" ? <Icon.StarFill /> : iconName === "StarHalf" ? <Icon.StarHalf /> : <Icon.Star />}
                                                                    </React.Fragment>
                                                                })}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className='feedbackImgHolder'>
                                                                {data?.feedback_images != "" ?
                                                                    data?.feedback_images.map((imgdta, imgind) => {
                                                                        return (
                                                                            <img key={imgind} onClick={() => showFeedbackImages(imgdta.url, data?.feedback_images)} src={imgdta.url} />
                                                                        )
                                                                    })
                                                                    : "No Images"
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            }

                            {modalType == "deleteStationimg" && <>
                                <div className='text-center mb-4'>
                                    <h4> Are you sure?</h4>
                                    <h5>You want to remove the logo.</h5>
                                </div>
                                <div className='text-center mt-2'>
                                    <button className='btn btn-danger btn-sm me-3' onClick={() => removestationLogo(popData)}> Confirm</button>
                                    <button className='btn btn-outline-danger btn-sm' onClick={handleClose} > Cancel</button>
                                </div>
                            </>}
                            {modalType == "updateLocation" && <>
                                <div className=' mb-4'>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label"><strong>Status</strong></label>
                                                <select name="status" className="form-select" value={stationPrefrences.status}
                                                    onChange={locationStatusChange}>
                                                    {chargerstatus.map((data, index) => {
                                                        return (
                                                            <option key={index} value={data.value}>{data.name}</option>
                                                        )
                                                    })}
                                                </select>
                                                {errors && stationPrefrences.status == "" ?
                                                    <div className='invalid-feedback  d-block'>Select status</div>
                                                    : null}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label"><strong>Parking</strong></label>
                                                {parkingValues.map((val, keyval) => {
                                                    return (
                                                        <div key={keyval} className="form-check">
                                                            <input className="form-check-input" type="radio" name="paid_parking" onChange={locationStatusChange} id={`parking${keyval}`} value={val.value}
                                                                checked={stationPrefrences.paid_parking == val.value}
                                                            />
                                                            <label className="form-check-label" htmlFor={`parking${keyval}`}>
                                                                {val.name}
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center1 mt-2'>
                                    <button className='btn btn-primary ' onClick={() => updateLocationstatus(popData)}> Confirm</button>
                                    <button className='btn btn-secondary ms-2' onClick={handleClose} > Cancel</button>
                                </div>
                            </>}

                            {modalType == "viewChargerProfile" && <>
                                <div>
                                    <div className=' list-group-item d-flex row mb-2' >
                                        <div className="col-lg-3 col-md-3 my-1">
                                            <label className="form-label" htmlFor="">Power</label>
                                            <input placeholder="Enter Power" type="text" name="power" className="form-control"
                                                value={chargerProfile.price.power || ""}

                                                //  value={data?.active ? pricingBox?.power : data?.power || "--"}  
                                                onChange={handleInputchange}
                                                readOnly={chargerProfile.priceEdit ? false : true}
                                                maxLength="20"
                                            />
                                            {chargerProfile.priceEdit && chargerProfile.price.power === "" && errors === true ? <div className='invalid-feedback mb-2 d-block'>Please add Power</div> : null}
                                        </div>
                                        <div className="col-lg-3 col-md-3 my-1">
                                            <label className="form-label" htmlFor="">Price (RM)</label>
                                            <input placeholder="Enter Price" type="number" name="price"
                                                value={chargerProfile.price.price || ""}
                                                // value={data?.active ? pricingBox?.price : data?.price  || "--"} 
                                                onChange={handleInputchange}
                                                readOnly={chargerProfile.priceEdit ? false : true}
                                                className="form-control" maxLength="20" />
                                            {chargerProfile.priceEdit && chargerProfile.price.price === "" && errors === true ? <div className='invalid-feedback mb-2 d-block'>Please add Price</div> : null}
                                            {chargerProfile.priceEdit && chargerProfile.price.price < 0 && errors === true ? <div className='invalid-feedback mb-2 d-block'>Price can not be negative</div> : null}
                                        </div>
                                        <div className="col-lg-3 col-md-3 my-1">
                                            <label className="form-label" htmlFor="">Unit</label>
                                            <input placeholder="Enter Unit" type="text" name="unit"
                                                value={chargerProfile.price.unit || ""}
                                                onChange={handleInputchange}
                                                readOnly={chargerProfile.priceEdit ? false : true}
                                                className="form-control" maxLength="20" />
                                            {chargerProfile.priceEdit && chargerProfile.price.unit === "" && errors === true ? <div className='invalid-feedback mb-2 d-block'>Please add unit</div> : null}

                                        </div>
                                        <div className="col-lg-3 col-md-3 my-1">
                                            <label className="form-label" htmlFor="">&nbsp;</label>
                                            <div>
                                                {chargerProfile.priceEdit ?
                                                    <>
                                                        <button className="btn btn-sm btn-outline-danger"
                                                            onClick={() => toggleEditState("priceEdit", false)}
                                                        > <Icon.XLg /> </button>
                                                        <button type="submit" className='btn btn-sm btn-primary ms-1'
                                                            onClick={() => updatePricing()}
                                                        >Update</button>
                                                    </> :
                                                    <button className={`btn btn-text btn-sm btn-primary`} onClick={() => toggleEditState("priceEdit", true)} >
                                                        {popData?.charger_profile && popData?.charger_profile?.price && popData?.charger_profile?.price?.price !== "" ? <Icon.Pencil /> : <Icon.PlusLg />}
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className=' list-group-item d-flex row mb-2' >
                                        <div className="col-lg-9 col-md-9 my-1">
                                            <label htmlFor="" className="form-label">Enter description*</label>
                                            <textarea name='description' className="form-control"
                                                value={chargerProfile.description || ""}
                                                readOnly={chargerProfile.descriptionEdit ? false : true}
                                                onChange={handleInputchange}
                                                placeholder='Enter description'
                                                rows={10} />
                                            {chargerProfile.description === "" && errors === true ?
                                                <div className='invalid-feedback mb-2 d-block'>Please add description</div> :
                                                null}
                                        </div>
                                        <div className="col-lg-3 col-md-3 my-1">
                                            <label className="form-label" htmlFor="">&nbsp;</label>
                                            <div>
                                                {chargerProfile.descriptionEdit ? <>
                                                    <button className="btn btn-sm btn-outline-danger"
                                                        onClick={() => toggleEditState("descriptionEdit", false)}
                                                    > <Icon.XLg /> </button>
                                                    <button type="submit" className='btn btn-sm btn-primary ms-1'
                                                        onClick={() => updatedescription()}
                                                    >Update</button></>
                                                    : <button className={`btn btn-text btn-sm btn-primary`}
                                                        onClick={() => toggleEditState("descriptionEdit", true)}
                                                    >

                                                        {popData?.charger_profile && popData?.charger_profile?.description?.description !== "" ? <Icon.Pencil /> : <Icon.PlusLg />}
                                                    </button>}


                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='text-center1 mt-2'>
                                        <button className='btn btn-primary' onClick={() => updateLocationstatus(popData)}> Confirm</button>
                                        <button className='btn btn-secondary ms-2' onClick={handleClose} > Cancel</button>
                                    </div> */}
                                </div>
                            </>}
                        </Modal.Body>
                    </Modal>
                    <Modal show={feedbackModal} onHide={closefeedbackModal} size="xl" >
                        <Modal.Header closeButton>
                            <Modal.Title>Feedback images</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='feedbackImagesGrid'>
                                <div className='feedbackImagesGrid__left'>
                                    {feedbackImages.list.map((data, indx) => {
                                        return (
                                            <div key={indx} className={data.url === feedbackImages.main ? "active" : ""}>
                                                <img onClick={() => changfdbckMainImg(data.url)} src={data.url} alt="" />
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='feedbackImagesGrid__right'>
                                    <img src={feedbackImages.main} alt="" />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </main >}
        </>
    )
}
