import React, { useEffect, useState } from 'react'

import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment/moment';
import Index from '../../../../Assets/Images/Index';
import Form from 'react-bootstrap/Form';
import _ from "underscore"
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { formatDate } from '../../../../Config/formatDate';
import { energyfunction } from '../../../../Config/energyFunction';
import { energyfunctioncustom } from '../../../../Config/energyFunctionCustom';

import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, } from 'chart.js';
import { Bar } from "react-chartjs-2";

import Images from '../../../../Assets/Images/Index';
import { useSelector } from 'react-redux';


ChartJS.register(Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title,);




export const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
      text: ''
    }
  }
}
export const barChartData = (data) => {
  return {
    labels: data.label,
    datasets: [{
      label: false,
      data: data.data,
      backgroundColor: ['#F58E23'],
      // borderColor: ['#F58E23'],
      borderWidth: 0
    }]
  }
};

export const salesChartData = (data) => {
  return {
    labels: data.label,
    datasets: [{
      label: false,
      data: data.data,
      backgroundColor: ['#F58E23'],
      // borderColor: ['#F58E23'],
      borderWidth: 0
    }]
  }
};

const selessionOption = [
  { name: "Today", value: "today" },
  { name: "7 Days", value: "last_7_days" },
  { name: "30 Days", value: "last_30_days" },
]
const salesDate = [
  { name: "7 Days", value: "7days" },
  { name: "14 Days", value: "14days" },
  { name: "30 Days", value: "30days" },

]

export default function DashBoard() {
  const dataval = useSelector((state) => state.dataValue)
  const searchParams = new URLSearchParams(window.location.search)
  const location = useLocation()
  let navigate = useNavigate()

  const [dashList, setDashList] = useState()
  const [loading, setloading] = useState(true)
  const [userList, setUserList] = useState()
  const [activeSession, setActiveSession] = useState({})
  const [usercount, setusercount] = useState({})
  const [params, setParams] = useState({
    filter: "today"
  })
  const [salesreport, setSalesreport] = useState({
    filter: "7days",
    data: [],
  })

  const setparam = (key, val) => {
    searchParams.set(key, val)
    const to = { pathname: location.pathname, search: searchParams.toString() };
    navigate(to, { replace: true });
  }
  const deleteparam = (key) => {
    searchParams.delete(key)
    const to = { pathname: location.pathname, search: searchParams.toString() };
    navigate(to, { replace: true });
  }

  const DashData = () => {
    Api.GetApi(endPoints.DASH, "").then(res => {
      if (res.error === true) {
        Api.Alert(res.response.data.error, "error")
      } else {
        setDashList(res.data.data)
        setloading(false)
      }
    })
  }
  const getusercount = (param) => {
    Api.GetApi(`${endPoints.USERCOUNT}${param}`).then(res => {
      if (res.error === true) {
        Api.Alert(res.response.data.error, "error")
      } else {
        console.log("res.data.data", res.data.data.userCounts)

        let barDate = []
        let barlabel = []

        res.data.data.userCounts.map((data) => {
          barDate.push(data.dateTime)
          barlabel.push(data.count)
        })
        setusercount({
          data: barlabel,
          label: barDate
        })
        setloading(false)
      }
    })
  }
  const getactiveSession = () => {
    Api.GetApi(endPoints.SESSION).then(res => {
      if (res.error === true) {
        Api.Alert(res.response.data.error, "error")
        setloading(false)
      } else {
        // console.log("res.data.", res.data.data)

        let arr = []
        res.data.data.length > 0 && res.data.data[0].result.length > 0 && res.data.data[0].result.map((opt) => {
          var a = moment(opt?.metrics?.chargingStart);
          var b = moment(opt?.metrics?.chargingStop);
          var duration = moment.duration(b.diff(a));
          duration.hours()
          duration.minutes()
          duration.seconds()
          let duration1 = `${duration.hours() > 0 ? `${duration.hours()}hr` : ""} 
                  ${duration.minutes() > 0 ? `${duration.minutes()}min ` : ""}       ${duration.seconds() > 0 ? `${duration.seconds()}s` : ""}`

          arr.push({ ...opt, duration: duration1 })
        })

        setActiveSession(arr)
        setloading(false)
      }
    })
  }

  const getUserList = (param) => {
    Api.GetApi(`${endPoints.USERLIST}${param}`).then(res => {
      if (res.error === true) {
        Api.Alert(res.response.data.error, "error")
      } else {

        setUserList(res.data.data)
        // setloading(false)
      }
    })
  }
  const updateBlockStat = (e) => {
    // UPDATEBLOCKSTAT
    Api.PutApi(endPoints.UPDATEBLOCKSTAT, { "_id": e.target.value }).then(res => {
      if (res.error === true) {
        Api.Alert(res.response.data.error, "error")
      } else {
        Api.Alert("Updated sucessfully", "success")
        getUserList(``)
      }
    })
  }

  const handleFilter = (e) => {
    console.log("e.target.value", e.target.value)
    setparam("filter", e.target.value)
    searchParams.set("filter", params.filter)
  }

  const salesFilter = (e) => {
    console.log("e.target.value", e.target.value)
    setSalesreport({ ...salesreport, filter: e.target.value })
  }

  // DASHSALESREPORT
  const getsalesReport = () => {
    Api.GetApi(`${endPoints.DASHSALESREPORT}?filter=${salesreport.filter}`).then(res => {
      if (res.error === true) {
        Api.Alert(res.response.data.error, "error")
      } else {
        console.log("res.data.data", res.data.data)
        let barDate = []
        let barlabel = []

        res.data.data.map((data) => {
          barDate.push(data.amount)
          barlabel.push(moment(data?._id?.date).format("DD-MMM"))
        })

        setSalesreport({
          ...salesreport,
          data: {
            data: barDate,
            label: barlabel
          }
        })

      }
    })
  }

  useEffect(() => {
    DashData()
    getUserList(``)
    getactiveSession()
    getsalesReport()
  }, [])
  useEffect(() => {
    getsalesReport()
  }, [salesreport.filter])



  useEffect(() => {
    if (searchParams.size == 0) {
      searchParams.set("filter", params.filter)
      const to = { pathname: location.pathname, search: searchParams.toString() };
      navigate(to, { replace: true });
    }

    getusercount(location.search)
  }, [location.search])


  // console.log(activeSession, "activeSession")
  // console.log(userList, "userList")
  console.log(salesreport, "salesreport")
  return (
    <>

      {loading ?
        <div className="loader-line"></div> :
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Dashboard</h1>
          </div>
          <section className='section dashboard '>

            <div className="row ">
              <div className="mb-2 col-lg-12">
                <div className="row  g-1 g-md-2 ">
                  <div className="col-xxl-4 col-6  order-xxl-1">
                    <NavLink to={dataval?.isSupport ? "" : "/charging-station"} className="card info-card sales-card mb-0">
                      <div className="card-body dashCard">
                        <h5 className="card-title pb-0">Charging Stations </h5>
                        <div className="d-flex gap-1 gap-md-2">
                          <div className="card-icon rounded-circle ">
                            <Icon.EvStation className="me-0" />
                          </div>
                          <div className="ps-1">
                            <h6>{dashList?.chargingStation}</h6>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="col-xxl-4 col-6  order-xxl-2">
                    <div className="card info-card sales-card  mb-0">
                      <div className="card-body dashCard">
                        <h5 className="card-title pb-0">Charging Stations Online </h5>
                        <div className="d-flex gap-1 gap-md-2">
                          <div className="card-icon rounded-circle ">
                            <Icon.EvFront className="me-0" />
                          </div>
                          <div className="ps-1">
                            <h6>{dashList?.chargingStationOnline}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-6  order-xxl-4">
                    <div className="card info-card sales-card  mb-0">
                      <div className="card-body dashCard">
                        <h5 className="card-title pb-0">Total Sessions </h5>
                        <div className="d-flex gap-1 gap-md-2">
                          <div className="card-icon rounded-circle ">
                            <Icon.ClockHistory className="me-0" />
                          </div>
                          <div className="ps-1">
                            <h6>{dashList?.sessions}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-6  order-xxl-5">
                    <NavLink to="/users" className="card info-card sales-card  mb-0">
                      <div className="card-body dashCard">
                        <h5 className="card-title pb-0">Users </h5>
                        <div className="d-flex gap-1 gap-md-2">
                          <div className="card-icon rounded-circle ">
                            <Icon.Person className="me-0" />
                          </div>
                          <div className="ps-1">
                            <h6>{dashList?.user}</h6>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="col-xxl-4 col-md-6 col-6  order-xxl-3">
                    <div className="card info-card sales-card  mb-0">
                      <div className="card-body dashCard">
                        <h5 className="card-title pb-0">Today's Sales</h5>
                        <div className="d-flex gap-1 gap-md-2">
                          <div className="card-icon rounded-circle ">
                            <Icon.CurrencyDollar className="me-0" />
                            {/* <img src={Images.moneyBag} /> */}
                            <small><strong>MYR</strong></small>
                          </div>
                          <div className="ps-1">
                            <h6>
                              <CurrencyFormat value={parseFloat(dashList?.salesToday?.amount || "0").toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />
                            </h6>
                            <small>{dashList?.salesToday?.count || "0"} Transaction(s)</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-md-6 col-6  order-xxl-6">
                    <div className="card info-card sales-card  mb-0">
                      <div className="card-body dashCard">
                        <h5 className="card-title pb-0">Total Energy Sold</h5>
                        <div className="d-flex gap-1 gap-md-2">
                          <div className="card-icon rounded-circle ">
                            {/* <img src={Images.bolt} /> */}
                            <Icon.LightningChargeFill className="me-0" />
                            <small><strong>{energyfunctioncustom(dashList?.energy_report, "unitOnly")}</strong></small>
                          </div>
                          <div className="ps-1">
                            <h6>
                              {/* {energyfunction(dashList?.energy_report)}
                              <br /> */}
                              {energyfunctioncustom(dashList?.energy_report, "NumberOnly")} </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row ">
                  <div className=" col-xl-6">
                    <div className="card info-card sales-card">
                      <div className="card-body dashCard">
                        <div className="d-flex align-items-center justify-content-between mb-1">

                          <h5 className="card-title pb-2 m-md-0">User Signup</h5>
                          <div className="d-flex align-items-center justify-content-end">
                            <select className="ms-2 form-select dashFilterSelect form-md" aria-label="Default select example " value={searchParams.get("filter") || ""} onChange={handleFilter}>
                              {selessionOption.map((data, indx) => {
                                return <option value={data.value} key={indx}>{data.name}</option>
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="dashBarChartHolder" style={{
                          height: "200px",
                        }}>
                          <Bar options={barChartOptions} data={barChartData(usercount)} />

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-xl-6">
                    <div className="card info-card sales-card">
                      <div className="card-body dashCard">
                        <div className="d-flex align-items-center justify-content-between mb-1">

                          <h5 className="card-title pb-2 m-md-0">Sales</h5>
                          <div className="d-flex align-items-center justify-content-end">
                            <select className="ms-2 form-select dashFilterSelect form-md" aria-label="Default select example "
                              value={salesreport.filter || ""} onChange={salesFilter}
                            >
                              {salesDate.map((data, indx) => {
                                return <option value={data.value} key={indx}>{data.name}</option>
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="dashBarChartHolder" style={{
                          height: "200px",
                        }}>
                          <Bar options={barChartOptions} data={salesChartData(salesreport.data)} />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </section>

          <section className='section  '>
            <div className="card">
              <div className="card-body">
                <div className='d-flex justify-content-between mb-3'>
                  <h5 className="card-title pb-0 mb-0">Active Sessions</h5>
                  {activeSession && activeSession.length > 0 ? <NavLink to="/sessions/active">View all</NavLink> : null}
                </div>

                {activeSession && activeSession.length > 0 ?
                  <div className="table-responsive">
                    <table className="table dataTable mb-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th className='sorting' scope="col">User</th>
                          <th className='sorting' scope="col">Station</th>
                          <th scope="col">Start Time</th>
                          <th scope="col">Cost<small>(MYR)</small> </th>
                          <th scope="col">Payment Method</th>
                          <th scope="col">Status</th>
                          <th scope="col">Payment Status</th>
                          <th className='sorting' scope="col">Session Id</th>
                        </tr>
                      </thead>
                      <tbody>
                        {activeSession.slice(0, 5).map((data, index) => {
                          return (
                            <tr key={index} >
                              <td>{params.page > 1 && params.page}{index + 1}</td>
                              <td>  <Link to={`/users/${data?.user?._id}`} className='text-capitalized'>  {data?.user?.first_name} {data?.user?.last_name}</Link></td>
                              <td>{data?.chargingstation?.endpoint || "--"}</td>
                              <td>
                                {/* {data && data?.metrics && moment(data?.metrics.chargingStart).format("DD-MM-YYYY hh:mm a")} */}
                                {formatDate(data?.metrics?.chargingStart)}
                              </td>
                              <td><CurrencyFormat value={parseFloat(data?.cost?.amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} /></td>
                              <td>{data?.wallet ? "Wallet" : "Card"}</td>
                              <td>{data?.status} </td>
                              <td><span className={`badge ${data?.payment ? "bg-success" : "bg-danger"}`}>{data?.payment ? "Paid" : "Unpaid"}</span></td>
                              <td>{data?._id}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div> :
                  <div className='text-center py-3'>
                    <h3>No active session</h3>
                  </div>
                }
              </div>
            </div>

          </section>
          <section className='section  '>
            <div className="card">
              <div className="card-body">
                <div className='d-flex justify-content-between mb-3'>
                  <h5 className="card-title pb-0 mb-0">Recent Users</h5>
                  {userList?.result && userList?.result.length > 0 ? <NavLink to="/users">View all</NavLink> : null}
                </div>

                {userList?.result && userList?.result.length > 0 ?
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Created </th>
                          <th scope="col">Sessions</th>
                          {/* <th scope="col"> Card</th> */}
                          <th scope="col">Active/Blocked</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userList?.result.slice(0, 5).map((data, indx) => {
                          return (
                            <tr key={indx}>
                              <th scope="row">{params.page > 1 && params.page}{indx + 1}</th>
                              <td>{data?._id?.first_name}&nbsp;{data?._id?.last_name}</td>
                              <td>{data?._id?.email}</td>
                              <td>{data?._id?.country_code} {data?._id?.phone_number}</td>
                              <td>{moment(data?._id?.createdAt).format("lll")}</td>
                              <td>{data?._id?.session > 0 ? <Link to={`/users/session/${data?._id?._id}`}>{data?._id?.session} </Link> : data?._id?.session}</td>
                              {/* <td>
                                <div className="cardDetailHolder">
                                  {_.uniq(data?._id?.card.brand).map((opt, i) => {
                                    return (
                                      <div className='cardDetail' key={i}>
                                        <span className='cardDetail__icon'><img src={_.uniq(data?._id?.card?.image)[i] || Index.defaultCard} /></span>
                                        <span className='cardDetail__text'>{_.uniq(data?._id?.card?.last4)[i] || "----"}</span>
                                      </div>
                                    )
                                  })}
                                </div>
                              </td> */}
                              <td>
                                {!dataval?.isSupport ?
                                  <Form>
                                    <Form.Check // prettier-ignore
                                      type="switch"
                                      id={data?._id?._id}
                                      label={data?._id?.active ? "Active" : "Blocked"}
                                      checked={data?._id?.active}
                                      value={data?._id?._id}
                                      onChange={updateBlockStat}
                                    />
                                  </Form>
                                  :
                                  <p>{data?._id?.active ? "Active" : "Blocked"}</p>
                                }
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>

                  </div>
                  :
                  <div className='text-center py-3'>
                    <h3>No result found</h3>
                  </div>
                }
              </div>
            </div>

          </section>
        </main>
      }

    </>
  )
}
