import React, { useEffect, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import _ from "underscore";
import Modal from 'react-bootstrap/Modal';
import { set } from 'date-fns';
import { useSelector } from 'react-redux';
export default function Notification() {
    const dataval = useSelector((state) => state.dataValue)
    const [show, setShow] = useState(false);
    const [modalData, setmodalData] = useState("")
    const [modalType, setmodalType] = useState("")
    const [modaltitle, setmodaltitle] = useState("")
    const [btnDisable, setbtnDisable] = useState(false)
    const [error, seterror] = useState(false)
    const [notifiObj, setnotifiObj] = useState({
        title: "",
        description: "",
        image: "",

    })
    const maxTitleLength = 100
    const maxdescLength = 300
    const [titleLength, settitleLength] = useState(0)
    const [descLength, setdescLength] = useState(0)
    const [loading, setloading] = useState(true)
    const handleClose = () => {
        seterror(false)
        setShow(false)
        setnotifiObj({
            title: "",
            description: "",
            image: ""
        })
        setpreview(null)
    };
    const handleShow = () => setShow(true);
    const showmodal = (data, type, title) => {
        setmodalData(data)
        setmodaltitle(title)
        setmodalType(type)
        setShow(true)
    }
    const [notificationList, setnotificationList] = useState([])
    const [file, setFile] = useState(null)
    const [preview, setpreview] = useState(null)
    const handleFileChange = (event, id) => {
        let reader = new FileReader();
        let profile = event.target.files[0];
        if (profile.type.startsWith("image/")) {

            reader.onloadend = () => {
                if (profile.size <= 5000000) {
                    setpreview(reader.result)
                    console.warn("preview", preview)
                    setFile(profile)
                    const img = new Image();
                    img.src = URL.createObjectURL(profile);

                    img.onload = () => {
                        // if (img.width > 600 && img.height > 180) { 

                        setnotifiObj({ ...notifiObj, "image": profile })
                        // data.append("locationId", id) 
                        // } else {
                        // Api.Alert("Images size should be 600 X 180 ", "error")
                        // }
                    }
                } else {
                    Api.Alert("Max file size 5MB", "error")
                }

            };

        } else {
            Api.Alert("Uploaded file is not a valid image. Only JPG, PNG and GIF files are allowed. ", "error")
        }

        if (!_.isUndefined(profile)) {
            reader.readAsDataURL(profile);
        }
    }

    const getNotificationList = () => {
        Api.GetApi(endPoints.NOTIFICATIONLIST).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)

            } else {
                console.log("res", res.data.data)
                setloading(false)
                setnotificationList(res.data.data)


            }
        })

    }
    const handleChange = (e) => {
        // setnotifiObj({ ...notifiObj, [e.target.name]: e.target.value.replace(/\s/g, '') })
        setnotifiObj({ ...notifiObj, [e.target.name]: e.target.value })

    }
    useEffect(() => {
        settitleLength(notifiObj.title.length)
        setdescLength(notifiObj.description.length)
    }, [notifiObj])


    const createNotification = (cond) => {
        console.log("notifiObj ", notifiObj)

        if (!_.isEmpty(notifiObj) && notifiObj.title == "") {
            seterror(true)
        } else if (!_.isEmpty(notifiObj) && notifiObj.description == "") {
            seterror(true)
        } else if (notifiObj.title.length > maxTitleLength) {
            seterror(true)
        } else if (notifiObj.description.length > maxdescLength) {
            seterror(true)
        } else {
            setbtnDisable(true)
            seterror(false)


            let data = new FormData();
            data.append("title", notifiObj.title)
            data.append("description", notifiObj.description)
            if (notifiObj.image && notifiObj.image != "") {
                data.append("notification_image", notifiObj.image)
            }
            data.append("send", cond)


            Api.PostApi(endPoints.NOTIFICATION, data).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    Api.Alert(res.data.data, "success")
                    getNotificationList()
                    setnotifiObj({
                        title: "",
                        description: "",
                        image: ""
                    })
                    handleClose()
                    setbtnDisable(false)

                }
            })
        }

    }
    const confirmDelete = () => {
        Api.DeleteApi(`${endPoints.NOTIFICATION}/${modalData}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)

            } else {
                setloading(false)
                handleClose()
                Api.Alert(res.data.data, "success")
                getNotificationList()

            }
        })
    }

    const sendNotofication = () => {
        Api.PostApi(`${endPoints.NOTIFICATIONSEND}/${modalData}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")


            } else {
                Api.Alert(res.data.data, "success")
                setloading(false)
                handleClose()
                getNotificationList()
            }
        })
    }

    useEffect(() => {
        getNotificationList()
    }, [])

    console.log("notifiObj ", notifiObj.title.length)
    // console.log("notificationList.map ", notificationList)
    console.log("notifiObj", notifiObj)
    return (
        <>
            {loading ?
                <div className="loader-line"></div> :
                <main id="main" className="main">
                    <div className="pagetitle d-flex justify-content-between align-items-center">
                        <h1>Notification</h1>
                        <button className="btn btn-primary" onClick={() => showmodal("sdsd", "addnotification", "Add Notification")}>Add New</button>
                    </div>
                    <div className="card">
                        <div className="card-body ">
                            {notificationList && notificationList.length > 0 ?
                                <div className="table-responsive">
                                    <table className="table dataTable">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th className='sorting' scope="col">Title</th>
                                                <th scope="col" >Description</th>
                                                <th scope="col" >Image</th>
                                                {dataval?.isSupport ?
                                                    null :
                                                    <th scope="col">Push Notification</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {notificationList && notificationList.length > 0 &&
                                                // .slice() 
                                                // .sort((a, b) => (a.send === b.send ? a._id.localeCompare(b._id) : a.send ? -1 : 1))                                     
                                                _.sortBy(notificationList, "send").map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td><div className='breakWhitespace' style={{ maxWidth: "450px", }}>{data?.title}</div></td>
                                                            <td><div className='breakWhitespace' style={{ maxWidth: "450px", minWidth: "250px", }}>{data?.description}</div></td>
                                                            <td> <div className='breakWhitespace' style={{ minWidth: "200px", }}>{data?.image ?
                                                                <div className='notificationImage'> <img src={data?.image} alt="" /> </div> : "No image"}
                                                            </div>
                                                            </td>
                                                            {dataval?.isSupport ?
                                                                null :
                                                                <td>
                                                                    <div className='notificationTableBtn'>
                                                                        <button className='btn btn-success btn-sm ms-2' onClick={() => showmodal(data?._id, "sendNotification")} >{data?.send ? "Resend" : "Send"}</button>
                                                                        <button className="deleteBtn btn btn-danger btn-sm ms-2" onClick={() => showmodal(data?._id, "deleteStationimg")} title="Remove notification">
                                                                            <Icon.Trash />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            }
                                                        </tr>
                                                    )
                                                })}

                                        </tbody>
                                    </table>

                                </div> : <div className='text-center py-3'>
                                    <h3>No result found</h3>
                                </div>
                            }
                        </div>
                    </div>
                </main>}
            <Modal show={show} centered size='md' onHide={handleClose}>
                {modalType === "addnotification" ? <Modal.Header closeButton>
                    <Modal.Title className='h5 modalTitleCstm'> {modaltitle} </Modal.Title>
                </Modal.Header> : null

                }
                <Modal.Body>
                    {modalType === "addnotification" ? <>
                        <div className="row g-3">
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="">Title*</label>
                                <input placeholder="" type="text" name="title" onChange={handleChange} className="form-control" maxLength={maxTitleLength} />
                                <small>{titleLength}/{maxTitleLength}</small>
                                {notifiObj.title == "" && error === true ? <div className='invalid-feedback mb-2 d-block'>Please add Title</div> : null}
                                {notifiObj.title.length > maxTitleLength && error === true ? <div className='invalid-feedback mb-2 d-block'>Character limit is {maxTitleLength}</div> : null}

                            </div>
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="">Description*</label>
                                <textarea maxLength={maxdescLength} className="form-control resize-none" name="description" onChange={handleChange} placeholder="" rows="3"></textarea>
                                <small>{descLength}/{maxdescLength}</small>
                                {notifiObj.description == "" && error === true ? <div className='invalid-feedback mb-2 d-block'>Please add Description</div> : null}
                                {notifiObj.description.length > maxdescLength && error === true ? <div className='invalid-feedback mb-2 d-block'>Character limit is {maxdescLength}</div> : null}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="">Image</label>
                                <div className='logoUpdateBox d-block'>
                                    {/* <div className='logoUpdateBox__btnHolder d-flex'> */}
                                    {/* <label className="uploadBtn  " title="Upload new image"> */}
                                    {/* <Icon.Camera /> */}
                                    <input type='file' className="form-control" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleFileChange(e, "112")} />
                                    {/* </label> */}
                                    {/* </div> */}
                                    <div className='mt-2'>
                                        <div className='addnotificationPriview'>
                                            <img src={preview && preview} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-4'>
                            {/* onClick={handleClose} */}
                            <button className='btn btn-primary btn-sm' disabled={btnDisable} onClick={() => createNotification(false)}> Save </button>
                            <button className='btn btn-success btn-sm ms-2' disabled={btnDisable} onClick={() => createNotification(true)}> Save & Send </button>
                        </div>
                    </> : null}
                    {modalType == "deleteStationimg" && <>
                        <div className='text-center mb-4'>
                            <h4> Are you sure?</h4>
                            <h5>You want to remove the notification.</h5>
                        </div>
                        <div className='text-center mt-2'>
                            <button className='btn btn-danger btn-sm me-3' onClick={confirmDelete}> Confirm</button>
                            <button className='btn btn-outline-danger btn-sm' onClick={handleClose} > Cancel</button>
                        </div>
                    </>}
                    {modalType == "sendNotification" && <>
                        <div className='text-center mb-4'>
                            <h4> Are you sure?</h4>
                            <h5>You want to send the notification.</h5>
                        </div>
                        <div className='text-center mt-2'>
                            <button className='btn btn-danger btn-sm me-3' onClick={sendNotofication}> Confirm</button>
                            <button className='btn btn-outline-danger btn-sm' onClick={handleClose} > Cancel</button>
                        </div>
                    </>}
                </Modal.Body>
            </Modal>
        </>
    )
}
