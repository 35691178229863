import React, { useEffect, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CurrencyFormat from 'react-currency-format';
import { Pagination } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addYears } from "date-fns";
import axios from 'axios';
import Index from '../../../../Assets/Images/Index';
import { formatDate } from '../../../../Config/formatDate';
import _ from 'underscore';
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from 'react-redux';


const maxdescLength = 300
export default function Cimb() {
    const dataval = useSelector((state) => state.dataValue)
    const searchParams = new URLSearchParams(window.location.search)
    const [listLength, setlistLength] = useState({
        sessionLength: 0,
        walletLength: 0,
    })
    const [loading, setloading] = useState(true)
    const [sessionCloseId, setsessionCloseId] = useState()
    const [show, setShow] = useState(false);
    const [modelType, setmodelType] = useState("")
    const [planId, setplanId] = useState({
        benefit_id: ""
    })
    let limit = 20
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
    })
    const [editedValues, seteditedValues] = useState({
        value: 0,
        id: ""
    })

    const [errors, setErrors] = useState(false)
    const [loadingBtn, setloadingBtn] = useState(false);
    const [BenefitsList, setBenefitsList] = useState({})
    const [Benefitsdescritpion, setBenefitsdescritpion] = useState({})
    const [chargerList, setchargerList] = useState({})
    const handleClose = () => {
        setErrors(false)
        setShow(false)
        setaddedChargerVal({
            charger_id: "",
            benefit_value: "",
        })
        setavchargerlist("")
        setchargerPage(1);
        setdescEditValues({
            benefit_description: "",
            description_id: ""
        })
        setdescType("")
        setdescLength(0)
    };
    const handleShow = () => setShow(true);
    const getbenefitslist = () => {
        Api.GetApi(`${endPoints.CIMBGETBENIFITS}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                setBenefitsList(res.data?.data?.benefits)
                setBenefitsdescritpion(res.data?.data?.description)
                setloading(false)
            }
        })
    }

    const [hasMore2, sethasMore2] = useState(false)
    const [chargerPage2, setchargerPage2] = useState(1)
    const [totalCharger2, settotalCharger2] = useState(0)
    const getAddedChargerList = (params, type) => {
        // CIMBCHARGERS
        Api.GetApi(`${endPoints.CIMBCHARGERS}/${params}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                if (type == "force") {
                    const newData = res.data?.data.result
                    if (chargerPage2 > 1) {
                        setchargerList(prevData => [...prevData, ...newData])
                    } else {

                        setchargerList(res.data?.data.result)
                    }
                } else {
                    setchargerList(res.data?.data.result)
                }
                if (res.data?.data?.result?.length < limit) {
                    sethasMore2(false)
                } else {
                    sethasMore2(true)
                }
                settotalCharger2(res.data?.data?.totalCount?.count)
                setloading(false)
            }
        })
    }

    const [avchargerlist, setavchargerlist] = useState({})
    const getAvialableChargerlist = (param) => {
        Api.GetApi(`${endPoints.CIMBAVAILABLECHARGERS}/${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                setloading(false)
                const newData = res.data?.data[0].result
                if (chargerPage > 1) {
                    setavchargerlist(prevData => [...prevData, ...newData])
                } else {
                    setavchargerlist(res.data?.data[0].result)
                }

                settotalCharger(res.data?.data[0].totalCount.count)
            }
        })

    }
    const [editModalTitle, seteditModalTitle] = useState("")
    const openEditPlanPop = (data) => {
        seteditModalTitle(data?.benefit_for)
        setShow(true)
        setmodelType("edit")
        seteditedValues({
            value: data.value,
            id: data._id
        })
    }
    const handleEditInputchange = (e) => {
        if (e.target.value < 0) {
            seteditedValues({ ...editedValues, [e.target.name]: 0 });
        } else {
            seteditedValues({ ...editedValues, [e.target.name]: parseFloat(e.target.value) });
        }
    }
    const updateBenifitValue = () => {
        let val = parseFloat(editedValues.value)
        if (val == null || val == "" || val < 0 || isNaN(val)) {
            setErrors(true)
        } else {
            setErrors(false)
            Api.PutApi(endPoints.CIMBUPDATEBENIFITS, editedValues).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                    setloading(false)
                } else {
                    Api.Alert(res.data.data, "success")
                    setloading(false)
                    handleClose()
                    getbenefitslist("")
                }
            })
        }
    }
    const [hasMore, sethasMore] = useState(true)
    const [chargerPage, setchargerPage] = useState(1)
    const [totalCharger, settotalCharger] = useState(0)
    const [addedChargerVal, setaddedChargerVal] = useState({
        charger_id: "",
        benefit_value: "",
    })
    const handleCheck = (e) => {
        if (e.target.name == "benefit_value") {
            if (e.target.value > 100) {
                setaddedChargerVal({ ...addedChargerVal, [e.target.name]: 100 })
            } else if (e.target.value < 0) {
                setaddedChargerVal({ ...addedChargerVal, [e.target.name]: 0 })

            } else {
                setaddedChargerVal({ ...addedChargerVal, [e.target.name]: e.target.value })
            }

        } else {
            setaddedChargerVal({ ...addedChargerVal, [e.target.name]: e.target.value })
        }
    }
    const addcharger = () => {
        if (addedChargerVal.charger_id == "" || addedChargerVal.benefit_value == "") {
            setErrors(true)
        } else {
            setErrors(false)
            let data = {
                charger_id: addedChargerVal.charger_id,
                benefit_value: parseInt(addedChargerVal.benefit_value),
            }
            Api.PostApi(endPoints.CIMBADDCHARGERS, data).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                    setloading(false)
                } else {
                    Api.Alert(res.data.data, "success")
                    setloading(false)
                    handleClose()
                    sethasMore2(true)
                    setchargerPage2(1)
                    getAddedChargerList(`?page=1`, "natural")
                }
            })
        }
    }
    const [removeChargerId, setremoveChargerId] = useState()

    const openEditchargerPop = (data) => {
        setShow(true)
        setmodelType("editCharger")
        setaddedChargerVal({
            charger_id: data._id,
            benefit_value: data.benefit_value,
        })
    }
    const openRemovechargerPop = (data) => {
        setShow(true)
        setmodelType("removeCharger")
        setremoveChargerId(data?._id)
    }
    const removeCharger = () => {
        Api.DeleteApi(`${endPoints.CIMBREMOVECHARGERS}/${removeChargerId}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                Api.Alert(res.data.data, "success")
                setloading(false)
                handleClose()
                setremoveChargerId("")
                sethasMore2(true)
                getAddedChargerList(`?page=1`, "force")
                setchargerPage2(1)
            }
        })

    }
    const updateCharger = () => {
        if (addedChargerVal.benefit_value == "") {
            setErrors(true)
        } else {
            setErrors(false)
            let data = {
                charger_id: addedChargerVal.charger_id,
                benefit_value: parseInt(addedChargerVal.benefit_value),
            }
            Api.PutApi(endPoints.CIMBUPDATECHARGERS, data).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                    setloading(false)
                } else {
                    Api.Alert(res.data.data, "success")
                    setloading(false)
                    handleClose()


                    let arr = []
                    // arr.filter((x) => x.charger_id === addedChargerVal.charger_id)
                    chargerList.map((x) => {
                        if (x._id === addedChargerVal.charger_id) {
                            arr.push({ ...x, benefit_value: addedChargerVal.benefit_value })
                        }
                        else {
                            arr.push(x)
                        }
                    })


                    setchargerList(arr)

                    // console.log("benefit_value", addedChargerVal.benefit_value, "charger_id", addedChargerVal.charger_id)


                    setaddedChargerVal({
                        charger_id: "",
                        benefit_value: "",
                    })

                    // getAddedChargerList(`?page=${chargerPage2}`, "force")
                }
            })
        }
    }
    const openChargerPop = () => {
        // getAvialableChargerlist(`?page=${chargerPage}`);

        getAvialableChargerlist(`?page=1`, "natural");
        loadMore()
        setShow(true)
        setmodelType("addcharger")
    }

    // description starts here
    const [descLength, setdescLength] = useState(0)
    const [descType, setdescType] = useState("")
    const [descEditValues, setdescEditValues] = useState({
        benefit_description: "",
        description_id: ""
    })
    const showadddescriptionPop = () => {
        setShow(true)
        setmodelType("handleDescription")
        setdescType("add")
    }

    const showeditdescriptionPop = (data) => {

        setShow(true)
        setdescEditValues({
            benefit_description: data.benefit_description,
            description_id: data._id,
        })
        setdescType("edit")
        setmodelType("handleDescription")
    }
    const handleEditDescriptiochange = (e) => {
        setdescEditValues({ ...descEditValues, [e.target.name]: e.target.value.replace(/  +/g, ' ') })
        setdescLength(e.target.value.length)

    }
    const editDescription = () => {
        if (descEditValues.benefit_description == "") {
            setErrors(true)
        } else {
            setErrors(false)
            let data = {
                description: descEditValues.benefit_description,
                id: descEditValues.description_id,
            }

            Api.PutApi(endPoints.CIMBUPDATEDESCRIPTION, data).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                    setloading(false)
                } else {
                    Api.Alert(res.data.data, "success")
                    setloading(false)
                    handleClose()
                    setdescType("")
                    setdescEditValues({
                        benefit_description: "",
                        description_id: ""
                    })
                    getbenefitslist("")
                }
            })
        }
    }
    const addDescription = () => {
        if (descEditValues.benefit_description == "") {
            setErrors(true)
        } else {
            setErrors(false)
            let data = {
                description: descEditValues.benefit_description,
            }
            Api.PostApi(endPoints.CIMBADDDESCRIPTION, data).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                    setloading(false)
                } else {
                    Api.Alert(res.data.data, "success")
                    setloading(false)
                    handleClose()
                    setdescType("")
                    setdescEditValues({
                        benefit_description: "",
                        description_id: ""
                    })
                    getbenefitslist("")
                }
            })
        }
    }
    const openDeleteDescPop = (id) => {
        setShow(true)
        setdescEditValues({
            benefit_description: "",
            description_id: id,
        })
        setmodelType("deleteDescription")
    }
    const deleteDescription = () => {
        Api.DeleteApi(`${endPoints.CIMBADDDESCRIPTION}/${descEditValues.description_id}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                Api.Alert(res.data.data, "success")
                setloading(false)
                handleClose()
                setdescEditValues({
                    benefit_description: "",
                    description_id: ""
                })
                getbenefitslist("")
            }
        })

    }


    const loadMore = () => {
        if (avchargerlist.length >= totalCharger) {
            sethasMore(false);
            return;
        } else {
            sethasMore(true);
            setTimeout(() => {
                setchargerPage(prevPage => prevPage + 1);
            }, 1000);
        }
    };
    const loadMore2 = () => {
        // if (chargerList.length >= totalCharger2) {
        //     sethasMore2(false);
        //     console.log("true")
        //     return;
        // } else {
        //     sethasMore2(true);
        //     console.log("fasle")
        setTimeout(() => {
            setchargerPage2(prevPage => prevPage + 1);
        }, 1000);
        // }
    };
    // console.log("chargerList.length", chargerList.length, "totalCharger2", totalCharger2)
    // console.log("hasMore2", hasMore2)

    useEffect(() => {
        getAvialableChargerlist(`?page=${chargerPage}`);
    }, [chargerPage]);


    useEffect(() => {
        getAddedChargerList(`?page=${chargerPage2}`, "force");
    }, [chargerPage2]);

    useEffect(() => {
        getbenefitslist("")
        // getAddedChargerList(`?page=${chargerPage2}`, "natural")
    }, [])
    console.log("hasMore", hasMore)
    return (
        <>
            {loading ?
                <div className="loader-line"></div> :
                <main id="main" className="main">
                    <div className="pagetitle pagetitleFilters d-flex flex-wrap  justify-content-between align-items-start">
                        <h1>CIMB Preferred EV+</h1>
                        <div> </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body ">
                                    <h5 className='card-title pb-0 mb-3'>Benefit List</h5>
                                    {BenefitsList && BenefitsList?.length > 0 ?
                                        <>
                                            <div className="table-responsive">
                                                <table className="table dataTable">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Benefit Type</th>
                                                            <th scope="col">Value</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {BenefitsList.map((data, index) => {
                                                            let updInd = index + 1
                                                            return (
                                                                <tr key={index} >
                                                                    <td>   {((searchParams.get("page") || 1) - 1) * 20 + updInd}</td>

                                                                    <td>{data?.benefit_for}</td>
                                                                    <td>
                                                                        <>{data?.type === "percentage" ? data?.value + "%"
                                                                            : <CurrencyFormat value={parseFloat(data?.value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />}</>
                                                                    </td>
                                                                    {dataval?.isSupport ?
                                                                        <td>-</td>
                                                                        :
                                                                        <td>
                                                                            <button className='btn btn-success btn-sm me-2'
                                                                                onClick={() => openEditPlanPop(data, "edit")} title='Edit '
                                                                            ><Icon.PencilFill /></button>
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </> :
                                        <div className='text-center py-5'>
                                            <h3>No result found</h3>
                                        </div>
                                    }

                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className="mb-3  d-flex align-items-center justify-content-between">
                                        <h5 className='card-title pb-0'>Benefit Description</h5>
                                        {dataval?.isSupport ? null :
                                            <button className='btn btn-primary' onClick={showadddescriptionPop}> Add New</button>
                                        }
                                    </div>
                                    {Benefitsdescritpion && Benefitsdescritpion.length > 0 ?
                                        <>
                                            <div className="table-responsive">
                                                <table className="table dataTable">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Benefit Description</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {Benefitsdescritpion.map((data, index) => {
                                                            let updInd = index + 1
                                                            return (
                                                                <tr key={index} >
                                                                    <td>   {((searchParams.get("page") || 1) - 1) * 20 + updInd}</td>
                                                                    <td style={{ maxWidth: "400px", wordBreak: "break-all", whiteSpace: "pre-wrap" }}>{data?.benefit_description}</td>
                                                                    {dataval?.isSupport ?
                                                                        <td>-</td>
                                                                        :
                                                                        <td >
                                                                            <button onClick={() => showeditdescriptionPop(data)} className='btn btn-success btn-sm me-2' title='Edit '
                                                                            ><Icon.PencilFill /></button>
                                                                            <button onClick={() => openDeleteDescPop(data?._id)} className='btn btn-danger btn-sm me-2' title='delete '
                                                                            ><Icon.Trash3Fill /></button>
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </> :
                                        <div className='text-center py-5'>
                                            <h3>No result found</h3>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body ">
                                    <div className="mb-3 d-flex align-items-center justify-content-between">
                                        <h5 className='card-title pb-0'>Charger List</h5>
                                        {dataval?.isSupport ? null :
                                            <button className='btn btn-primary' onClick={openChargerPop} > Add New</button>
                                        }
                                    </div>
                                    {chargerList && chargerList.length > 0 ?
                                        <>
                                            <InfiniteScroll
                                                dataLength={chargerList.length}
                                                next={loadMore2}
                                                hasMore={hasMore2}
                                                loader={<p><strong>Loading...</strong></p>}
                                                height={475}>
                                                <div className="table-responsive">
                                                    <table className="table dataTable">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">Charger Name</th>
                                                                <th scope="col">Value</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {chargerList.map((data, index) => {
                                                                let updInd = index + 1
                                                                return (
                                                                    <tr key={index} >
                                                                        <td> {((searchParams.get("page") || 1) - 1) * 20 + updInd}</td>
                                                                        <td>{data?.details?.endpoint}</td>
                                                                        <td>
                                                                            <>{data?.benefit_type === "percentage" ? data?.benefit_value + "%"
                                                                                : <CurrencyFormat value={parseFloat(data?.benefit_value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />}</>
                                                                        </td>
                                                                        {dataval?.isSupport ?
                                                                            <td>-</td>
                                                                            :
                                                                            <td>
                                                                                <button className='btn btn-success btn-sm me-2' title='Edit' onClick={() => openEditchargerPop(data)}><Icon.PencilFill /></button>
                                                                                <button className='btn btn-danger btn-sm me-2' title='delete' onClick={() => openRemovechargerPop(data)}><Icon.Trash3Fill /></button>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </InfiniteScroll>
                                        </> :
                                        <div className='text-center py-5'>
                                            <h3>No result found</h3>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose} centered size="md"
                    // size={modelType === "delete" ? "md" : "md"}
                    >
                        <Modal.Body className='py-4'>
                            {modelType === "delete" || modelType === "removeCharger" || modelType === "deleteDescription" ?
                                <>
                                    <div className='text-center mb-4'>
                                        <h4> Are you sure?</h4>
                                        <h5>You want to delete this {modelType === "delete" ? "benefit" : modelType === "deleteDescription" ? "description" : "charger"}.</h5>
                                    </div>
                                </> : null
                            }
                            {modelType === "edit" ?
                                <>
                                    <div className=' mb-4'>
                                        <h5> Edit {editModalTitle} Benefit Value</h5>
                                    </div>
                                    <div className=' mb-4'>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <Form.Group >
                                                    <Form.Label><strong>Value*</strong></Form.Label>
                                                    <Form.Control type="number" name="value" placeholder="Enter value"
                                                        onChange={(e) => handleEditInputchange(e)}
                                                        value={editedValues.value}
                                                        isInvalid={errors && editedValues?.value == "" || errors && isNaN(editedValues?.value)}
                                                        min="0"
                                                        step="1"
                                                    />
                                                    {errors && editedValues?.value == "" || errors && isNaN(editedValues?.value) ?
                                                        <Form.Control.Feedback type="invalid" className='d-block'>Please enter value</Form.Control.Feedback> : null}
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>

                                </> : null
                            }
                            {modelType === "editCharger" ?
                                <>
                                    <div className=' mb-4'>
                                        <h5> Edit Charger Value</h5>
                                    </div>
                                    <div className=' mb-4'>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <Form.Group >
                                                    <Form.Label><strong>Value*</strong></Form.Label>
                                                    <Form.Control type="number" name="benefit_value" placeholder="Enter value"
                                                        onChange={(e) => handleCheck(e)}
                                                        value={addedChargerVal.benefit_value}
                                                        isInvalid={errors && addedChargerVal?.benefit_value == "" || errors && addedChargerVal.benefit_value}
                                                        min="0"
                                                        step="1"
                                                    />
                                                    {errors && addedChargerVal.benefit_value == "" ?
                                                        <Form.Control.Feedback type="invalid" className='d-block'>Please enter value</Form.Control.Feedback> : null}
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>

                                </> : null
                            }

                            {modelType === "addcharger" ?
                                <>
                                    <div className=' mb-4'>
                                        <h5>Add Charger  </h5>
                                    </div>
                                    <div className=' mb-4'>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                {avchargerlist && avchargerlist.length > 0 ?
                                                    <InfiniteScroll
                                                        dataLength={avchargerlist.length}
                                                        next={loadMore}
                                                        hasMore={hasMore}
                                                        loader={<p><strong>Loading...</strong></p>}
                                                        height={300}>

                                                        {avchargerlist.map((opt, index) => {
                                                            return (
                                                                <Form.Check
                                                                    key={opt?._id}
                                                                    type={"radio"}
                                                                    id={opt?._id}
                                                                    label={opt?.endpoint || ""}
                                                                    value={opt?._id}
                                                                    name='charger_id'
                                                                    onChange={handleCheck}
                                                                />
                                                            )
                                                        })}
                                                    </InfiniteScroll>
                                                    : null}
                                            </div>
                                            {errors && addedChargerVal.charger_id == "" ?
                                                <Form.Control.Feedback type="invalid" className='d-block'>Please select charger</Form.Control.Feedback> : null}
                                            {addedChargerVal.charger_id != "" ?
                                                <div className="col-12">
                                                    <Form.Group >
                                                        <Form.Label><strong>Value*</strong></Form.Label>
                                                        <Form.Control type="number" name="benefit_value" placeholder="Enter value"
                                                            onChange={(e) => handleCheck(e)}
                                                            value={addedChargerVal.benefit_value}
                                                            isInvalid={errors && addedChargerVal?.benefit_value == "" || errors && addedChargerVal.benefit_value}
                                                            min="0"
                                                            step="1"
                                                        />
                                                        {errors && addedChargerVal.benefit_value == "" ?
                                                            <Form.Control.Feedback type="invalid" className='d-block'>Please enter value</Form.Control.Feedback> : null}
                                                    </Form.Group>
                                                </div> : null
                                            }

                                        </div>
                                    </div>
                                </> : null
                            }

                            {modelType === "handleDescription" ? <>
                                <div className=' mb-4'>
                                    <h5>{descType == "edit" ? "Edit" : "Add"}  Benefit Description</h5>
                                </div>
                                <div className=' mb-4'>
                                    <div className="row g-3">

                                        <div className="col-12">
                                            <Form.Group >
                                                <Form.Label><strong>Description*</strong></Form.Label>
                                                <Form.Control className='resize-none' placeholder="Enter description" as="textarea" name='benefit_description'
                                                    maxLength={maxdescLength} rows={4}
                                                    value={descEditValues?.benefit_description}
                                                    onChange={(e) => handleEditDescriptiochange(e)}
                                                    isInvalid={errors && descEditValues?.benefit_description == ""}
                                                />
                                                <small>{descLength}/{maxdescLength}</small>
                                                {errors && descEditValues?.benefit_description == "" ?
                                                    <Form.Control.Feedback type="invalid">Please enter description</Form.Control.Feedback> :
                                                    null}
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>

                            </> : null}


                            <div className={modelType === "delete" || modelType === "removeCharger" || modelType === "deleteDescription" ? "text-center mt-2" : "mt-2"}>
                                {modelType === "edit" ? <button className='btn btn-primary  me-2' onClick={updateBenifitValue} > Submit</button> : null}
                                {modelType === "addcharger" ? <button className='btn btn-primary  me-2' onClick={addcharger} > Add Charger</button> : null}
                                {modelType === "removeCharger" ? <button className='btn btn-primary  me-2' onClick={removeCharger}  > Confirm</button> : null}

                                {modelType === "editCharger" ? <button className='btn btn-primary  me-2' onClick={updateCharger} > Update</button> : null}

                                {descType == "add" ? <button className='btn btn-primary  me-2' onClick={addDescription} > Add Description</button> : null}
                                {modelType === "deleteDescription" ? <button className='btn btn-primary  me-2' onClick={deleteDescription}  > Confirm</button> : null}
                                {descType == "edit" ? <button className='btn btn-primary  me-2' onClick={editDescription}> Update</button> : null}

                                <button className='btn btn-outline-danger ' onClick={handleClose} > Cancel</button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </main >}
        </>
    )
}

