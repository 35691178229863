import React, { useEffect, useState } from 'react'
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
    const dataval = useSelector((state) => state.dataValue)
    console.log("dataval sidebar", dataval)
    const [nav, setNav] = useState([
        {
            name: "Dashboard", link: "/dashboard", icon: <Icon.Grid className="me-2" />, show: true, support: true
        },
        {
            name: "Sessions", link: "/sessions?page=1", icon: <Icon.Clock className="me-2" />, show: true, support: true, submenu: [
                { name: "Active Sessions", link: "/sessions/active", icon: <Icon.HourglassSplit className="me-2" />, },
                { name: "Unpaid Sessions", link: "/sessions/unpaid?page=1", icon: <Icon.Hourglass className="me-2" />, },
            ]
        },
        { name: "Charging Stations", link: "/charging-station", icon: <Icon.EvStation className="me-2" />, show: true, support: false },

        { name: "Station Profile", link: "/station-profile", icon: <Icon.PersonLinesFill className="me-2" />, show: true, support: false },

        { name: "Users", link: "/users?page=1", icon: <Icon.Person className="me-2" />, show: true, support: true },
        { name: "Co-Partners Management", link: "/co-partners?page=1", icon: <Icon.Diagram2 className="me-2" style={{ fontSize: "20px" }} />, show: true, support: false },
        { name: "Payment Gateway", link: "/payment", icon: <Icon.CreditCard className="me-2" />, show: true, support: false },

        // { name: "Pricing", link: "/pricing", icon: <Icon.Cash className="me-2" />, show: true, support: false },

        { name: "Notification", link: "/notification", icon: <Icon.Bell className="me-2" />, show: true, support: false },

        // { name: "Announcement", link: "/announcement", icon: <Icon.Megaphone className="me-2" />, },

        { name: "User Wallet Transactions", link: "/transactions?page=1", icon: <Icon.Wallet className="me-2" />, show: true, support: true },
        { name: "Top Up List", link: "/topup?page=1", icon: <Icon.ListCheck className="me-2" />, show: true, support: false },
        // { name: "Subscription Plan", link: "/subscription", icon: <Icon.Activity className="me-2" />, },
        { name: "Wallet Benefits", link: "/subscription/benefits", icon: <Icon.Award className="me-2" />, show: true, support: false },
        // {
        //     name: "Subscription Plans", link: "/subscription", icon: <Icon.Activity className="me-2" />, submenu: [
        //         { name: "Benefits", link: "/subscription/benefits", icon: <Icon.Award className="me-2" />, },
        //     ], show: true, support: false
        // },
        {
            name: "CIMB Preferred EV+", link: "/cimb", icon: <Icon.Flower2 className="me-2" />, submenu: [
                { name: "CIMB Code", link: "/cimb/code", icon: <Icon.List className="me-2" />, },
            ], show: true, support: false
        },

        // 

        { name: "Admin Wallet", link: "/system-wallet", icon: <Icon.Wallet className="me-2" />, show: true, support: true },
        { name: "Voucher Bonus", link: "/bonus", icon: <Icon.Percent className="me-2" />, show: true, support: false },
        { name: "Maintenance Mode", link: "/maintenance", icon: <Icon.Gear className="me-2" />, show: true, support: false },
        { name: "OTPs", link: "/otp", icon: <Icon.ChatLeftDots className="me-2" />, show: true, support: true },
        { name: "Intelligence Hub", link: "/intelligence-hub/revenue-business-performance", icon: <Icon.GraphUpArrow className="me-2" />, show: true, support: true, submenu: [
            { name: "Revenue & business performance", link: "/intelligence-hub/revenue-business-performance", icon: <Icon.PiggyBank className="me-2" />, },
            { name: "Customer behavior & retention", link: "/intelligence-hub/customer-behavior-retention", icon: <Icon.Diagram3Fill className="me-2" />, },
            { name: "Operational & charging network", link: "/intelligence-hub/operational-charging-network", icon: <Icon.LightningCharge className="me-2" />, },
        ]}
    ])

    const dataVal = useSelector((state) => state.dataValue)
    useEffect(() => { }, [dataVal])
    return (
        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">
                {nav.filter((x) => dataval?.isSupport ? x.support : x.show).map((data, index) => {
                    return (
                        <li className="list_item" key={index}>
                            <NavLink className="nav-link " to={data.link}>
                                <span className='nav-link-icon'>
                                    {data.icon}
                                </span>
                                {data.name}
                            </NavLink>
                            {data.submenu ?
                                <>
                                    <ul className='nav-content collapse show'>
                                        {data.submenu.map((cntnt, ind) => {
                                            return <li key={ind}>
                                                <NavLink className="nav-link " to={cntnt.link}>
                                                    <span className='nav-link-icon'>
                                                        {cntnt.icon}
                                                    </span>
                                                    {cntnt.name}
                                                </NavLink>
                                            </li>
                                        })}

                                    </ul>
                                </>
                                : null}
                        </li>
                    )
                })}
            </ul>

        </aside>
    )
}

export default Sidebar;