import React from "react";
import GaugeChart from "react-gauge-chart";

const GaugeComponent = ({ value }) => {
  return (
    <div style={{ position: "relative" }}>
      <GaugeChart
        id="gauge-chart"
        nrOfLevels={4}
        percent={value / 100}
        colors={["red", "orange", "yellow", "#6cc900"]}
        arcWidth={0.3}
        needleColor="black"
        textColor="#000"
        formatTextValue={(v) => `${v}%`}
      />
      <div className="gauge_text" style={{ position: "absolute", top: "85%", left: "12%", fontWeight: "bold"}}>Very Poor</div>
      <div className="gauge_text" style={{ position: "absolute", top: "0%", left: "30%", fontWeight: "bold"}}>Poor</div>
      <div className="gauge_text" style={{ position: "absolute", top: "0%", right: "30%", fontWeight: "bold"}}>Fair</div>
      <div className="gauge_text" style={{ position: "absolute", top: "85%", right: "12%", fontWeight: "bold" }}>Excellent</div>
    </div>
  );
};

export default GaugeComponent;
