import React, { useEffect, useState } from 'react'

import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment/moment';
import _ from "underscore"
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

import { useSelector } from 'react-redux';
import HorizontalBarChart from './Charts/HorizontalBarChart';
import TimeSeriesHeatmap from './Charts/TimeChart';
import ColumnChart from './Charts/ColumnChart';
import StackedBarChart from './Charts/StackedBarChart';
// import { BoxPlot } from 'chartjs-chart-box-and-violin-plot';
// import BoxPlotChartComponent from './Charts/BoxPlot';
import BoxPlotChart from './Charts/BoxPlot';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const filter2 = [
  { name: "Filter", value: "" },
  { name: "Last 30 days", value: "last30days " },
  { name: "Last 60 days", value: "last60days" },
  { name: "Last 90 days", value: "last90days" },
  { name: "Last 120 days", value: "last120days" },
  { name: "Last 180 days", value: "last180days" },
  { name: "Last 365 days", value: "last365days" },
]
const type = [
  { name: "Type", value: "" },
  { name: "AC", value: "AC" },
  { name: "DC", value: "DC" },
]

export default function OperationalCharging() {

  const [chartData, setchartData] = useState({})
  const [loading, setloading] = useState(true)

  const [dates, setDates] = useState({
    "PEAKCHARGINGHOURS": {
      startDate: null,
      endDate: null
    },
    "SESSIONDURATION": {
      startDate: null,
      endDate: null
    },
    "AVERAGECHARGINGDURATION": {
      startDate: null,
      endDate: null
    },
    "SESSIONDURATIONBOXPLOT": {
      startDate: null,
      endDate: null
    }

  });

  function convertMinutesToTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    const seconds = Math.round((minutes % 1) * 60); // Convert decimal part to seconds
    return `${hours}hr:${mins.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  const getData = (url, param) => {
    Api.GetApi(`${endPoints[url]}${param}`).then(res => {
      console.log(`res123 ${url}`, res)
      setTimeout(() => {
        setloading(false)
      }, 1000);
      if (res.error === true) {
        Api.Alert(res.response.data.error, "error")
      } else {
        setchartData((prevData) => ({
          ...prevData,
          [url]: {
            ...prevData[url],
            data: res.data.data
          },
        }));
        // setloading(false)
      }
    })
  }

  const handleFilter = (e, key) => {
    if(key == "type"){
      setDates((prevDates) => ({
        ...prevDates,
        [e.target.name]: {
          ...prevDates[e.target.name],
          [key]: e.target.value
        }
      }));
    }
    if (e.target.value) {
      getData(e.target.name, `?${key}=${e.target.value}`)
    } else {
      getData(e.target.name, "")
    }
  }

  const onDateChange = (dates, name) => {
    const [start, end] = dates;
    setDates((prevDates) => ({
      ...prevDates,
      [name]: {
        ...prevDates[name],
        startDate: start,
        endDate: end
      }
    }));
    if (start && end) {
      getData(name, `?from=${moment(start).format("YYYY-MM-DD")}&to=${moment(end).format("YYYY-MM-DD")}`)
    } else {
      if (!start) {
        getData(name, "")
      }
    }
  };
  console.log("dates", dates)

  useEffect(() => {
    const arr = [
      "SESSIONDURATION",
      "GETTOP",
      "PEAKCHARGINGHOURS",
      "AVERAGECHARGINGDURATION",
      "SESSIONDURATIONBOXPLOT"
    ]
    arr.map((data) => {
      getData(data, "")
    })
  }, [])

  console.log("chartData", chartData)

  return (
    <>

      {loading ?
        <div className="loader-line"></div> :
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Operational & charging network</h1>
          </div>
          <section className='section dashboard '>
            <div className='row'>
              <div className="col-md-12">
                <div className="card info-card sales-card mb-4">
                  <div className="card-body dashCard">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h5 className="card-title pb-2 m-md-0">Peak Charging Times - AC & DC</h5>
                      <div className='date-range-outer'>
                        <DatePicker
                          onChange={(e) => onDateChange(e, "PEAKCHARGINGHOURS")}
                          selected={dates.PEAKCHARGINGHOURS.startDate}
                          startDate={dates.PEAKCHARGINGHOURS.startDate}
                          endDate={dates.PEAKCHARGINGHOURS.endDate}
                          selectsRange
                          maxDate={new Date()}
                          placeholderText="Select date range"
                          className="form-control datepicker"
                          isClearable={true}
                        />
                      </div>
                    </div>

                    {chartData && chartData.PEAKCHARGINGHOURS && chartData.PEAKCHARGINGHOURS.data ?
                      <div className="dashBarChartHolder" style={{
                        width: "100%", height: "100%"
                      }}>
                        <TimeSeriesHeatmap
                          data={chartData.PEAKCHARGINGHOURS.data}
                        />
                      </div>
                      : null
                    }
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card info-card sales-card">
                  <div className="card-body dashCard">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <h5 className="card-title pb-2 m-md-0">Geographical Distribution</h5>
                      <div className="d-flex align-items-center">
                        <select className="me-2 form-select dashFilterSelect form-md" aria-label="Default select example" name="SESSIONDURATION" onChange={(e) => handleFilter(e, "type")} style={{flex: "0 0 100px"}}>
                          {type.map((data, indx) => {
                            return <option value={data.value} key={indx}>{data.name}</option>
                          })}
                        </select>
                        <div className='date-range-outer'>
                          <DatePicker
                            onChange={(e) => onDateChange(e, "SESSIONDURATION")}
                            selected={dates.SESSIONDURATION.startDate}
                            startDate={dates.SESSIONDURATION.startDate}
                            endDate={dates.SESSIONDURATION.endDate}
                            selectsRange
                            maxDate={new Date()}
                            placeholderText="Select date range"
                            className="form-control datepicker"
                            isClearable={true}
                          />
                        </div>
                      </div>
                    </div>
                    {chartData && chartData.SESSIONDURATION && chartData.SESSIONDURATION.data ?
                      <div className="dashBarChartHolder" style={{
                        width: "100%",
                      }}>
                        <HorizontalBarChart
                          data={chartData.SESSIONDURATION.data.map((data) => data.charger && data.charger.session_count || 0)}
                          labels={chartData.SESSIONDURATION.data.map((value) => value.operator_name)}
                          label={"Sessions"}
                        />
                      </div>
                      : null
                    }
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="card info-card sales-card">
                  <div className="card-body dashCard">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <h5 className="card-title pb-2 m-md-0">Top 10 Chargers with Highest Utilization Rate (AC+DC)</h5>
                      <div className="d-flex align-items-center justify-content-end">
                        <select className="ms-2 form-select dashFilterSelect form-md" aria-label="Default select example" name="GETTOP" onChange={(e) => handleFilter(e, "filter")}>
                          {filter2.map((data, indx) => {
                            return <option value={data.value} key={indx}>{data.name}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    {chartData && chartData.GETTOP && chartData.GETTOP.data ?
                      <div className="dashBarChartHolder" style={{
                        width: "100%",
                      }}>
                        <HorizontalBarChart
                          data={chartData.GETTOP.data.map((data) => data.utilizationRate || 0)}
                          labels={chartData.GETTOP.data.map((value) => value.charger.label)}
                          label={"Utilization Rate"}
                        />
                      </div>
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>


            <div className="card info-card sales-card mb-4">
              <div className="card-body dashCard">
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <h5 className="card-title pb-2 m-md-0">Average Charging Duration Per Session</h5>
                  <div className='date-range-outer'>
                    <DatePicker
                      onChange={(e) => onDateChange(e, "AVERAGECHARGINGDURATION")}
                      selected={dates.AVERAGECHARGINGDURATION.startDate}
                      startDate={dates.AVERAGECHARGINGDURATION.startDate}
                      endDate={dates.AVERAGECHARGINGDURATION.endDate}
                      selectsRange
                      maxDate={new Date()}
                      placeholderText="Select date range"
                      className="form-control datepicker"
                      isClearable={true}
                    />
                  </div>
                </div>
                <div className='row g-5'>
                  <div className="col-lg-6">
                    {chartData && chartData.AVERAGECHARGINGDURATION && chartData.AVERAGECHARGINGDURATION.data.length ?
                      <div className="dashBarChartHolder" style={{
                        height: "auto",
                      }}>
                        <ColumnChart
                          data={[
                            {
                              label: "AC",
                              data: chartData.AVERAGECHARGINGDURATION.data.map((c) => {
                                const acCharger = c.charger?.find((ch) => ch._id === "AC"); // Find AC charger
                                return acCharger ? acCharger.average_duration : 0;
                              }),
                              backgroundColor: "rgba(75, 192, 192, 1)", // Green bars
                              borderRadius: 0,
                            },
                            {
                              label: "DC",
                              data: chartData.AVERAGECHARGINGDURATION.data.map((c) => {
                                const dcCharger = c.charger?.find((ch) => ch._id === "DC"); // Find DC charger
                                return dcCharger ? dcCharger.average_duration : 0;
                              }),
                              backgroundColor: "rgba(255, 99, 132, 1)", // Red bars
                              borderRadius: 0,
                            },
                          ]}
                          label={chartData.AVERAGECHARGINGDURATION.data.map((_,) => ` `)}
                          // tooltip={chartData.AVERAGECHARGINGDURATION.data.map((value) => `Location: ${value.operator_name}`)}
                          tooltip={{
                            callbacks: {
                              title: (tooltipItems) => {
                                const index = tooltipItems[0].dataIndex;
                                return `Location: ${chartData.AVERAGECHARGINGDURATION.data[index].operator_name}`;
                              },
                              label: function (tooltipItem) {
                                console.log("tooltipItem", tooltipItem)
                                const index = tooltipItem.dataIndex;
                                const datasetLabel = tooltipItem.dataset.label;
                                const value = tooltipItem.raw; // The data value


                                return `${datasetLabel}: ${convertMinutesToTime(value)}`;
                              },
                            },
                          }}
                        />
                      </div>
                      : null
                    }
                  </div>
                  <div className="col-lg-6">
                    {chartData && chartData.AVERAGECHARGINGDURATION && chartData.AVERAGECHARGINGDURATION.data.length ?
                      <div className="dashBarChartHolder" style={{
                        height: "auto",
                      }}>
                        <StackedBarChart
                          data={[
                            {
                              label: "Session durations(minutes)",
                              data: chartData.AVERAGECHARGINGDURATION.data.map((c) => c.total.average_duration || 0),
                              backgroundColor: "rgba(255, 99, 132, 1)",
                            }
                          ]}
                          labels={chartData.AVERAGECHARGINGDURATION.data.map((c) => c.operator_name || "")}
                          tooltip={chartData.AVERAGECHARGINGDURATION.data.map((c) => `Location: ${c.operator_name || ""}\n Duration time: ${convertMinutesToTime(c.total.average_duration || 0)}`)}
                          stack={false}
                          legend
                          position={"bottom"}
                        />
                      </div>
                      : null
                    }
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h5 className="card-title pb-2 m-md-0"></h5>
                      <div className='date-range-outer'>
                        <DatePicker
                          onChange={(e) => onDateChange(e, "SESSIONDURATIONBOXPLOT")}
                          selected={dates.SESSIONDURATIONBOXPLOT.startDate}
                          startDate={dates.SESSIONDURATIONBOXPLOT.startDate}
                          endDate={dates.SESSIONDURATIONBOXPLOT.endDate}
                          selectsRange
                          maxDate={new Date()}
                          placeholderText="Select date range"
                          className="form-control datepicker"
                          isClearable={true}
                        />
                      </div>
                    </div>
                    {chartData && chartData.SESSIONDURATIONBOXPLOT && chartData.SESSIONDURATIONBOXPLOT.data.length ?
                      <div className="dashBarChartHolder" style={{
                        height: "auto",
                      }}>
                        <BoxPlotChart
                          data={chartData.SESSIONDURATIONBOXPLOT.data.map((data) => [
                            data.stats?.lowerBound || 0, // Min
                            data.stats?.Q1 || 0,         // Q1
                            data.stats?.median || 0,     // Median
                            data.stats?.Q3 || 0,         // Q3
                            data.stats?.upperBound || 0  // Max
                          ])}
                          labels={chartData.SESSIONDURATIONBOXPLOT.data.map((_) => ` `)}
                          tooltip={chartData.SESSIONDURATIONBOXPLOT.data.map((c) => `Location: ${c.location.operator_name || ""}`)}
                        />
                      </div>
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>

        </main>
      }

    </>
  )
}
