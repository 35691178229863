import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import CurrencyFormat from 'react-currency-format';
import axios from 'axios';
import moment from 'moment/moment';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { formatDate } from '../../../../Config/formatDate';
import ResponsivePagination from 'react-responsive-pagination';
import { useSelector } from 'react-redux';


export default function SystemWallet() {
    const dataval = useSelector((state) => state.dataValue)
    const searchParams = new URLSearchParams(window.location.search)
    const param = useParams()
    let navigate = useNavigate()
    const location = useLocation()
    const [walletBalance, setwalletBalance] = useState("")
    const [OTPList, setOTPList] = useState([])
    const [loading, setloading] = useState(false)
    const [popData, setPopData] = useState({})
    const [show, setShow] = useState(false);
    const [errors, seterrors] = useState(false)
    const [modalType, setmodalType] = useState("")
    const [loadingBtn, setloadingBtn] = useState(false)
    const [popValue, setpopValue] = useState({
        amount: ""
    })
    const [count, setCount] = useState(1)
    let limit = 20
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
        type: searchParams.get("type") || "",
        search: searchParams.get("search") || "",
    })
    const [search, setsearch] = useState(searchParams.get("search") || "")

    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }


    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        seterrors(false)
        setpopValue({
            amount: ""
        })

    }
    const handleChange = (e) => {
        setpopValue({ ...popValue, [e.target.name]: e.target.value })
    }

    const getOTPList = (param) => {
        Api.GetApi(`${endPoints.GETOTP}/${param}`).then(res => {
            console.log("res", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {

                if (res.data.data.length > 0) {
                    let newCount = Math.ceil(parseInt(res.data.data[0].totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }


                setloading(false)
                setOTPList(res?.data?.data[0]?.result)
            }
        })
    }

    const walletAction = () => {
        setmodalType("walletAction")
        setShow(true)
    }

    const submitWalletAction = () => {
        if (popValue.amount === "" || popValue.amount <= "0") {
            seterrors(true)
        } else {
            setloadingBtn(true)
            seterrors(false)
            let data = {}
            data = {
                amount: parseFloat(popValue.amount),
            }
            updateWalletAction(data)
            setloadingBtn(false)
            // ADDWALLETCREDIT 
        }
    }


    const updateWalletAction = (data) => {
        Api.PostApi(endPoints.ADDWALLETCREDIT, data).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                Api.Alert(res.data.data, "success")
                handleClose()
                setloading(false)
            }
        })

    }

    useEffect(() => {
        setParams({ ...params, page: searchParams.get("page") || 1 })
    }, [searchParams.get("page")])

    const setCurrentPage = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        } else {
            setparam("page", number)
            setParams({ ...params, page: number })
        }
    }

    const searchUser = (e) => {
        deleteparam("page")
        setsearch(e.target.value)
        if (e.target.value == "") {
            setParams({ ...params, page: 1, search: "" })
            deleteparam("search")
        } else {
            // setparam([e.target.name], e.target.value)
        }
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search != "") {
                setParams({ ...params, page: 1, search: search })
                setparam('search', search)
            }
            // DashData(`?search=${search}`)
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    useEffect(() => {
        setParams({ ...params, page: searchParams.get("page") || 1 })
    }, [searchParams.get("page")])

    const clearSearch = () => {
        setParams({
            ...params,
            search: "",
        })
        setsearch("")
        deleteparam("search")
    }


    useEffect(() => {
        if (!searchParams.get("search") ) {
        
            clearSearch()
        }

        if (searchParams.get("page")  && searchParams.get("page")  != params.page) {  
            setParams({ ...params, page: searchParams.get("page")  }) 
        }
        getOTPList(location.search)
    }, [location.search])

    return (

        <main id="main" className="main">
            <div className='d-flex align-items-center justify-content-between mb-md-3'>
                <div className="pagetitle">
                    <h1>One time passwords(OTPs)</h1>
                </div>
            </div>
            {loading ?
                <div className="loader-line"></div> :
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex mb-0 mb-md-4 justify-content-between align-items-center userdetailTopRow">
                                    <div className='userdetailTopRow__left col-12 col-md-5'>
                                        <div className="d-flex align-items-start align-items-sm-center  mb-3 mb-md-2 flex-column flex-sm-row" >
                                            <div className="search-bar pe-1  ">
                                                <div className="search-form d-flex align-items-center" >
                                                    <input type="text" className='form-control'
                                                        name="search" placeholder="Search by code/phone number" value={search} title="Enter search keyword" onChange={searchUser} />
                                                    <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                                </div>
                                            </div>
                                            {search !== "" ?
                                                <button type="button" className="textClear nowrap " onClick={clearSearch}>Clear Search</button> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                {OTPList && OTPList.length > 0 ? <>
                                    <div className="table-responsive">
                                        <table className="table dataTable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Code</th>
                                                    <th scope="col">Phone no.</th>
                                                    <th scope="col">Service</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {OTPList.map((data, index) => {

                                                    let updInd = index + 1
                                                    return (
                                                        <tr key={index}>
                                                            <td>{(params.page - 1) * 20 + updInd}</td>
                                                            <td>{data?.code}</td>
                                                            <td>{data?.country_code} {data?.phone_number}</td>
                                                            <td>{data?.otp_service}</td>
                                                            <td className='text-capitalized'>{formatDate(data?.createdAt)}</td>
                                                            <td className='text-capitalized'>
                                                                <span className={data?.isExpired ? 'badge bg-danger' : "badge bg-success"}>
                                                                    {data?.isExpired ? "Expired" : "Active"}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )


                                                })}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div style={{ maxWidth: "500px" }}>
                                        <ResponsivePagination
                                            current={parseFloat(searchParams.get("page"))}
                                            total={count.length}
                                            onPageChange={setCurrentPage}
                                            disabled={params.page === count.length}
                                            // maxWidth={500}
                                            className={"pagination "}
                                        />
                                    </div>

                                </>
                                    :
                                    <>
                                        <div className='text-center py-3'>
                                            <h3>No OTP found</h3>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

        </main>
    )
}
