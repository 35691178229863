import React from "react";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend, LineElement, PointElement } from "chart.js";
import { Bar } from "react-chartjs-2";

// Register chart components
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const MultiAxisChart = (props) => {
    const { data1, data2, labels, label, tooltip } = props
    const data = {
        labels: label,
        datasets: [
            {
                label: labels[0],
                data: data1,
                borderColor: "rgba(255, 99, 132, 1)",
                backgroundColor: "rgba(255, 99, 132, 1)",
                type: "line",
                yAxisID: "y-left", // Left Y-Axis
            },
            {
                label: labels[1],
                data: data2,
                borderColor: "rgba(75, 192, 192, 1)",
                backgroundColor: "rgba(75, 192, 192, 1)",
                type: "line",
                yAxisID: "y-right", // Right Y-Axis
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                grid: { display: false }, ticks: { display: false }
            },
            "y-left": {
                type: "linear",
                position: "left",
                ticks: { beginAtZero: true },
            },
            "y-right": {
                type: "linear",
                position: "right",
                ticks: { beginAtZero: true },
                grid: { drawOnChartArea: false }, // Prevents overlapping gridlines
            },
        },
        elements: {
            point: {
              radius: 5,          // Default dot size
              hitRadius: 10,      // Expands hover detection area
              hoverRadius: 8,     // Increases dot size when hovered
              hoverBorderWidth: 3 // Adds a border on hover for visibility
            }
          },
        plugins: {
            legend: { position: "top" },
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        const index = tooltipItems[0].dataIndex;
                        return tooltip[index];
                    },
                },
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default MultiAxisChart;
