import React, { useEffect, useState } from 'react'

import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment/moment';
import _ from "underscore"
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

import { useSelector } from 'react-redux';
import ColumnChart from './Charts/ColumnChart';
import GaugeComponent from './Charts/GaugeChart';
import LineChart from './Charts/LineChart';


const filter2 = [
  { name: "Filter", value: "" },
  { name: "Last 30 days", value: "last30days " },
  { name: "Last 60 days", value: "last60days" },
  { name: "Last 90 days", value: "last90days" },
  { name: "Last 120 days", value: "last120days" },
  { name: "Last 180 days", value: "last180days" },
  { name: "Last 365 days", value: "last365days" },
]
export default function CustomerBehavior() {
  const dataval = useSelector((state) => state.dataValue)
  const searchParams = new URLSearchParams(window.location.search)
  const location = useLocation()
  let navigate = useNavigate()

  const [chartData, setchartData] = useState({})
  const [loading, setloading] = useState(true)

  const getData = (url, param) => {
    Api.GetApi(`${endPoints[url]}${param}`).then(res => {
      console.log(`res123 ${url}`, res)
      setTimeout(() => {
        setloading(false)
      }, 1000);
      if (res.error === true) {
        Api.Alert(res.response.data.error, "error")
      } else {
        setchartData((prevData) => ({
          ...prevData,
          [url]: {
            ...prevData[url],
            data: res.data.data
          },
        }));
        // setloading(false)
      }
    })
  }

  const handleFilter = (e, key) => {
    if (e.target.value) {
      getData(e.target.name, `?${key}=${e.target.value}`)
    } else {
      getData(e.target.name, "")
    }
  }

  useEffect(() => {
    const arr = [
      "ACTIVEINACTIVEUSERS",
      "ACTIVERATIO"
    ]
    arr.map((data) => {
      getData(data, "")
    })
  }, [])

  console.log("chartData", chartData)

  return (
    <>

      {loading ?
        <div className="loader-line"></div> :
        <main id="main" className="main">
          <div className="pagetitle">
            <h1> Customer behavior & retention insights</h1>
          </div>
          <section className='section dashboard '>
            <div className='row'>
              <div className="col-md-12">
                <div className="card info-card sales-card mb-4">
                  <div className="card-body dashCard">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h5 className="card-title pb-2 m-md-0">Active vs. Inactive Users</h5>
                      <div className="d-flex align-items-center justify-content-end">
                        <select className="ms-2 form-select dashFilterSelect form-md" aria-label="Default select example" name="ACTIVERATIO" onChange={(e) => handleFilter(e, "filter")}>
                          {filter2.map((data, indx) => {
                            return <option value={data.value} key={indx}>{data.name}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    {chartData && chartData.ACTIVERATIO && chartData.ACTIVERATIO.data.length ?
                      <div className="dashBarChartHolder" style={{
                        height: "300px",
                      }}>
                        <LineChart
                          data={chartData.ACTIVERATIO.data}
                          labels={chartData.ACTIVERATIO.data.map((data) => data._id || "")}
                          chart={[
                            {
                              label: "Active Users",
                              data: chartData.ACTIVERATIO.data.map((data) => data.active_users || 0),
                              borderColor: "green",
                              backgroundColor: "rgba(0, 255, 0, 0.2)",
                              borderWidth: 2,
                              fill: false,
                              tension: 0.1,
                              pointRadius: 0
                            },
                            {
                              label: "Inactive Users",
                              data: chartData.ACTIVERATIO.data.map((data) => data.inactive_user || 0),
                              borderColor: "red",
                              backgroundColor: "rgba(255, 0, 0, 0.2)",
                              borderWidth: 2,
                              fill: false,
                              tension: 0.1,
                              borderDash: [5, 5],
                              pointRadius: 0
                            },
                          ]}
                        />
                      </div>
                      : null
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card info-card sales-card mb-3">
                  <div className="card-body dashCard">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <h5 className="card-title pb-2 m-md-0">Customer Churn Rate</h5>
                      <div className="d-flex align-items-center justify-content-end">
                        <select className="ms-2 form-select dashFilterSelect form-md" aria-label="Default select example" name="ACTIVEINACTIVEUSERS" onChange={(e) => handleFilter(e, "filter")}>
                          {filter2.map((data, indx) => {
                            return <option value={data.value} key={indx}>{data.name}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xxl-6">
                        <div className='row'>
                          {chartData && chartData.ACTIVEINACTIVEUSERS && chartData.ACTIVEINACTIVEUSERS.data ?
                            <div className="col-md-4 mb-md-0 mb-4">
                              <div className='row'>
                                <div className='col-md-12 col-sm-4 col-6'>
                                  <div className="d-flex gap-1 gap-md-2 mb-3">
                                    <div className="card-icon rounded-circle ">
                                      <Icon.Person className="me-0" />
                                    </div>
                                    <div className="ps-1">
                                      <div className="mb-2">
                                        <h2 className='mb-0'>
                                          <CurrencyFormat value={chartData.ACTIVEINACTIVEUSERS.data.active_users || 0} displayType={'text'} thousandSeparator={true} prefix={''} />
                                        </h2>
                                        <h5 className="card-title pb-0 font-med">Active users</h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-md-12 col-sm-4 col-6'>
                                  <div className="d-flex gap-1 gap-md-2 mb-3">
                                    <div className="card-icon rounded-circle ">

                                    </div>
                                    <div className="ps-1">
                                      <div className="mb-2">
                                        <h2 className='mb-0'>
                                          <CurrencyFormat value={chartData.ACTIVEINACTIVEUSERS.data.inactive_users || 0} displayType={'text'} thousandSeparator={true} prefix={''} />
                                        </h2>
                                        <h5 className="card-title pb-0 font-med">Inactive users</h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-md-12 col-sm-4 col-6'>
                                  <div className="d-flex gap-1 gap-md-2">
                                    <div className="card-icon rounded-circle ">

                                    </div>
                                    <div className="ps-1">
                                      <div className="mb-2">
                                        <h2 className='mb-0'>
                                          <CurrencyFormat value={chartData.ACTIVEINACTIVEUSERS.data.total_users || 0} displayType={'text'} thousandSeparator={true} prefix={''} />
                                        </h2>
                                        <h5 className="card-title pb-0 font-med">Total users</h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            : null
                          }
                          <div className="col-md-8">
                            {chartData && chartData.ACTIVEINACTIVEUSERS && chartData.ACTIVEINACTIVEUSERS.data ?
                              <div>
                                <GaugeComponent value={chartData.ACTIVEINACTIVEUSERS.data.churn_rate || 0} />
                              </div>
                              : null
                            }
                          </div>
                        </div>

                      </div>


                      <div className="col-xxl-6">
                        {chartData && chartData.ACTIVEINACTIVEUSERS && chartData.ACTIVEINACTIVEUSERS.data && chartData.ACTIVEINACTIVEUSERS.data.user_segmentation ?
                          <div className="dashBarChartHolder" style={{
                            width: "100%",
                          }}>
                            <ColumnChart
                              data={[
                                {
                                  label: "High Spenders",
                                  data: [chartData.ACTIVEINACTIVEUSERS.data.user_segmentation.high_spenders],
                                  backgroundColor: "rgba(255, 99, 132, 1)",
                                },
                                {
                                  label: "Regular Users",
                                  data: [chartData.ACTIVEINACTIVEUSERS.data.user_segmentation.regular_users],
                                  backgroundColor: "rgba(75, 192, 192, 1)",
                                },
                                {
                                  label: "Dormant Users",
                                  data: [chartData.ACTIVEINACTIVEUSERS.data.user_segmentation.dormant_users],
                                  backgroundColor: "rgb(75, 192, 128)",
                                },
                              ]}
                              label={[""]}
                              tooltip={{
                                callbacks: {
                                  title: (tooltipItems) => {
                                    const index = tooltipItems[0].dataIndex;
                                    return ` `;
                                  },
                                }
                              }}
                            />
                          </div>
                          : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="card info-card sales-card">
                  <div className="card-body dashCard">
                    <div className="mb-2">
                      <h5 className="card-title pb-2 m-md-0">Loyalty & Subscription Engagement</h5>
                    </div>
                    {chartData && chartData.ACTIVEINACTIVEUSERS && chartData.ACTIVEINACTIVEUSERS.data && chartData.ACTIVEINACTIVEUSERS.data.user_segmentation ?
                      <div className="dashBarChartHolder" style={{
                        width: "100%",
                      }}>

                      </div>
                      : null
                    }
                  </div>
                </div>
              </div> */}
            </div>



          </section >

        </main >
      }

    </>
  )
}
