import React from "react";
import { Chart as ChartJS, LinearScale, TimeScale, Tooltip, Legend, BarElement } from "chart.js";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-moment";

ChartJS.register(LinearScale, TimeScale, BarElement, Tooltip, Legend);

const getColor = (max, value) => {
    if (value === 0) return "rgba(255, 255, 255, 1)";
    if (max/4 > value) return "rgba(255, 204, 153, 1)";
    if (max/2 > value) return "rgba(255, 153, 102, 1)";
    if (max/3 > value) return "rgba(255, 102, 102, 1)";
    return "rgba(204, 51, 51, 1)";
};


const TimeSeriesHeatmap = ({ data }) => {
    let maxNum = 0
    const number = data.map((d) => d.session_count || 0)
    const maxNumber = Math.max(...number);
    console.log(maxNumber);
    const dataset = {
        // labels: data.map((d) => `${d._id}:00`),
        datasets: [
            {
                label: " ",
                data: data.map((d) => ({
                    x: `${d._id}:00`, // Ensure time is string
                    y: d.session_count, // The session count
                })),
                backgroundColor: data.map((d) => getColor(maxNumber, d.session_count)),
                borderWidth: 1,
                barPercentage: 1.0,
                categoryPercentage: 1.0,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: "category", // Ensure x-axis is categorical
                title: {
                    display: false,
                    text: "Time Slot",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Charging Sessions",
                },
            },
        },
        plugins: {
            legends: { display: false },
            elements: {
                point: {
                  radius: 5,          // Default dot size
                  hitRadius: 10,      // Expands hover detection area
                  hoverRadius: 8,     // Increases dot size when hovered
                  hoverBorderWidth: 3 // Adds a border on hover for visibility
                }
              },
            tooltip: {
                callbacks: {
                    label: (context) => `Sessions: ${data[context.dataIndex].session_count}`,
                },
            },
        },
    };
    return (
        <div className="TimeSeriesHeatmap">
            <Chart type="bar" data={dataset} options={options} />
        </div>
    );
};

export default TimeSeriesHeatmap;
