import React, { useEffect, useState } from 'react'
import Index from '../../../../Assets/Images/Index'
import Form from 'react-bootstrap/Form';
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';
import _ from "underscore"
import { useSelector } from 'react-redux';

export default function Maintenance() {
    const dataval = useSelector((state) => state.dataValue)
    const [loading, setloading] = useState(true)
    const [maintainance, setMaintainance] = useState({
        active: false,
        title: "",
        discription: ""
    })
    const [defaultMain, setdefaultMain] = useState()
    const [logs, setlogs] = useState([])

    const getMaintainance = () => {
        Api.GetApi(endPoints.MAINTAINANCE_MODE).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                if (res.data.data) {
                    setdefaultMain(res.data.data)
                    setMaintainance({
                        active: res.data.data.active,
                        title: res.data.data.title,
                        discription: res.data.data.discription,
                    })
                    if (res.data.data.active) {
                        Api.GetApi(endPoints.MAINTAINANCE_LOG).then(resp => {
                            if (resp.error === true) {
                                Api.Alert(resp.response.data.error, "error")
                            } else {
                                if (resp.data.data.length > 0) {
                                    if (resp.data.data[0].result.length > 0) {
                                        setlogs(resp.data.data[0].result[0])
                                    }
                                }
                            }
                        })
                    } else {
                        setlogs({})
                    }
                }
                setloading(false)
            }
        })
    }


    const handleChange = (e, length) => {
        if (e.target.value.length <= length) {
            setMaintainance({ ...maintainance, [e.target.name]: e.target.value })
        }
    }


    const updateMode = (data) => {
        Api.PutApi(endPoints.MAINTAINANCE_MODE, data).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                getMaintainance()
                Api.Alert("Updated sucessfully", "success")
            }
        })

    }

    const toggleMaintainMode = (e) => {
        setMaintainance({ ...maintainance, active: e.target.checked })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let data = { ...maintainance }

        if (maintainance.title == "") {
            delete data["title"]
        }
        if (maintainance.discription == "") {
            delete data["discription"]
        }
        updateMode(data)
    }


    useEffect(() => {
        getMaintainance()
    }, [])
    // MAINTAINANCE_MODE
    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Maintenance Mode</h1>
            </div>
            {loading ?
                <div className="loader-line"></div> :
                <div className="row">
                    {dataval?.isSupport ?
                        <div className='text-center'>
                            <div className="card">
                                <div className="card-body py-5">
                                    <h3>You don't have access to this page.</h3>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-body">


                                    <div className="mb-3">
                                        {/* <div>
                                        <h5 className="card-title m-0">Maintenance Mode</h5>
                                    </div> */}
                                        <div>
                                            <Form className='d-flex align-items-center'>
                                                <Form.Check
                                                    className='mb-0'
                                                    type="switch"
                                                    // id={data?._id?._id}
                                                    label={maintainance?.active ? "Enabled" : "Disabled"}
                                                    checked={maintainance?.active}
                                                    // value={data?._id?._id}
                                                    onChange={toggleMaintainMode}
                                                />
                                                {!_.isEmpty(logs) ?
                                                    <strong className='ms-3'>
                                                        {moment(logs?.start_at).fromNow()}
                                                    </strong>
                                                    : null
                                                }
                                            </Form>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <form onSubmit={handleSubmit}>
                                            {maintainance?.active ?
                                                <>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="">Title</label>
                                                        <input placeholder="" type="text" className="form-control" name='title' value={maintainance?.title} onChange={(e) => handleChange(e, 100)} required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="">Description</label>

                                                        <textarea rows="3" className="form-control" name='discription' value={maintainance?.discription} onChange={(e) => handleChange(e, 500)} required></textarea>
                                                        <p><small>{maintainance?.discription && maintainance?.discription.length || 0} of 500 characters used</small></p>
                                                    </div>
                                                </>
                                                : null}
                                            <button type='submit' className="btn btn-primary" >Update</button>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    }
                </div>}

        </main>
    )
}
