import React, { useEffect, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../Config/formatDate';
import ResponsivePagination from 'react-responsive-pagination';
import Modal from 'react-bootstrap/Modal';
import * as Icon from 'react-bootstrap-icons';
import { size } from 'underscore';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const options = [
    { name: "Name", value: "fullName", },
]
const sortTypes = [
    { name: "Ascending ", value: "asc", },
    { name: "Descending  ", value: "desc", },
]

const filterTypes = [
    { name: "Used", value: "used" },
    { name: "Unused", value: "unused" }
]
const options2 = [
    { name: "Paid", value: "Paid", },
    { name: "Unpaid", value: "Unpaid", },
    { name: "Cancelled", value: "Cancelled", },
    { name: "Refunded", value: "Refunded", }
]
const options3 = [];
for (let i = 10; i <= 100; i += 10) {
    options3.push(i);
}

export default function SerialNo() {
    const dataval = useSelector((state) => state.dataValue)
    const searchParams = new URLSearchParams(window.location.search)
    const location = useLocation()
    let navigate = useNavigate()
    const [errors, setErrors] = useState(false)
    const [show, setShow] = useState(false);
    const [modelType, setmodelType] = useState("")
    const [loading, setloading] = useState(false)
    let limit = 20
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
        sort_key: searchParams.get("sort_key") || "",
        sort_value: searchParams.get("sort_value") || "asc",
        search: searchParams.get("search") || "",
        used: searchParams.get("used") || "",
    })
    const [count, setCount] = useState(1)
    const [userList, setuserList] = useState([])
    const [searchProgress, setsearchProgress] = useState(false)
    const [quantity, setquantity] = useState({
        size: ""
    })
    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }

    const handleClose = () => {
        setErrors(false)
        setquantity({ size: "" })
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const openBulkcodePop = () => {
        setShow(true);

    }


    const addNumber = (body) => {
        Api.PostApi(endPoints.CIMBADDSERIALNUMBER, body).then(res => {
            console.log("res list", res)
            setloading(false)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                Api.Alert(res.data.data, "success")
                getUserList(location.search)
            }
        })
    }


    const handleValuechange = (e) => {
        setquantity({ [e.target.name]: e.target.value })
    }
    console.log("quantity", quantity)
    const addcodeInbulk = () => {
        if (quantity.size == "") {
            setErrors(true)
        } else {

            let data = {
                size: parseInt(quantity.size)
            }

            Api.PostApi(endPoints.CIMBADDSERIALNUMBERINBULK, data).then(res => {
                console.log("res list", res)
                setloading(false)
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    Api.Alert(res.data.data, "success")
                    getUserList(location.search)
                    setquantity({ size: "" })
                    handleClose()

                }
            })

        }


    }
    const getUserList = (param) => {
        Api.GetApi(`${endPoints.CIMBUSERLIST}${param}`).then(res => {
            console.log("res list", res)
            setloading(false)
            setsearchProgress(false)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                let arr = []
                if (res.data.data.length > 0) {
                    arr = res.data.data[0].result
                    let newCount = Math.ceil(parseInt(res.data.data[0]?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }
                setuserList(arr)
            }
        })
    }

    var randomstring = Math.random().toString(36).slice(-10).toUpperCase();

    useEffect(() => {
        setParams({ ...params, page: searchParams.get("page") || 1 })
    }, [searchParams.get("page")])


    const setCurrentPage = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        } else {
            setparam("page", number)
            setParams({ ...params, page: number })
        }
    }


    const [showFilterBtn, setshowFilterBtn] = useState(false);

    const clearFilter = () => {
        setshowFilterBtn(false)
        setParams({
            ...params,
            sort_key: "",
            sort_value: "asc",
            used: "",
        })
        deleteparam("sort_key")
        deleteparam("sort_value")
        deleteparam("used")
    }

    const clearSearch = () => {
        setParams({
            ...params,
            search: "",
        })
        setsearch("")
        deleteparam("search")
        setsearchProgress(true)
    }
    const [search, setsearch] = useState(searchParams.get("search") || "")
    const searchUser = (e) => {
        setsearch(e.target.value)
        if (e.target.value == "") {
            setParams({ ...params, 'page': 1, 'search': "" })
            deleteparam("search")
        } else {
            setparam("search", e.target.value)
        }
    }
    useEffect(() => {
        // deleteparam("page")
        const delayDebounceFn = setTimeout(() => {
            if (search != "") {
                setsearchProgress(true)
                setParams({ ...params, page: 1, search: search })
                setparam('search', search)

            }
            if (searchParams.get("used") || searchParams.get("sort_key")) {
                setshowFilterBtn(true)
            } else {
                setshowFilterBtn(false)
                clearFilter()

            }

        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    const onSelectChange = (e) => {
        setshowFilterBtn(true)
        setParams({ ...params, [e.target.name]: e.target.value })
        if (e.target.name == "sort_value") {
            if (params.sort_key != "") {
                setparam("sort_value", e.target.value)
            } else {
                deleteparam("sort_value")
            }
        } else if (e.target.name == "used") {
            setParams({ ...params, [e.target.name]: e.target.value, page: 1 })
            setparam([e.target.name], e.target.value)
            setparam("page", 1)
        }
        else {
            if (e.target.name == "sort_key") {
                setparam([e.target.name], e.target.value)
                setparam("sort_value", params.sort_value)
            }
            else {
                setparam([e.target.name], e.target.value)
            }
        }
        // DashData(`?sort_key=${params.sort_key}&sort_value=${params.sort_value}&page=${params.page}`)
    }
    const generateNumber = () => {
        addNumber({ number: randomstring })
    }


    useEffect(() => {

        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        getUserList(location.search)
    }, [params])

    console.log("searchProgress: ", searchProgress)
    console.log("userList: ", userList)
    return (
        <main id="main" className="main">
            <div className="pagetitle d-flex justify-content-between align-items-center">
                <h1>CIMB Code</h1>
                {dataval?.isSupport ?
                    null
                    :
                    <div>
                        <button className="btn btn-danger" onClick={generateNumber}>Generate Code</button>
                        <button className="btn btn-danger ms-1" onClick={openBulkcodePop}>Generate Multiple Codes</button>
                    </div>
                }
            </div>
            {loading ?
                <div className="loader-line"></div>
                :
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex mb-0 mb-md-4 justify-content-between align-items-center userdetailTopRow userdetailTopRow--sessionpage">
                                    <div className='userdetailTopRow__left  '>
                                        <div className="d-flex align-items-start align-items-sm-center  mb-3 mb-md-2 flex-column flex-sm-row  " >
                                            <div className="search-bar pe-1  ">
                                                <div className="search-form d-flex align-items-center" >
                                                    <input type="text" className='form-control'
                                                        name="search" placeholder="Search" value={search} title="Enter search keyword" onChange={searchUser} autoComplete="off" disabled={searchProgress} />
                                                    <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                                </div>
                                            </div>
                                            {search !== "" ?
                                                <button type="button" disabled={searchProgress} className="textClear nowrap " style={{ opacity: searchProgress ? "0" : "1" }} onClick={clearSearch}>Clear Search</button> : null
                                            }
                                        </div>
                                    </div>
                                    <div className=" d-md-flex align-items-center userdetailTopRow__right userdetailTopRow__right--sessionpage" >
                                        <div className='my-2 me-2 d-flex flex-wrap'>
                                            <div className='my-1 me-2'>
                                                <select className='sortingSelect' name='used' value={params.used} onChange={onSelectChange}>
                                                    <option disabled defaultValue value="">Filter By</option>
                                                    {filterTypes.map((data, index) => {
                                                        return (
                                                            <option key={index} value={data.value}>{data.name}</option>
                                                        )
                                                    })}
                                                </select>

                                            </div>
                                        </div>
                                        {/* <div className='my-2  d-flex flex-wrap'>
                                            <div className='my-1 me-2'>
                                                <select className='sortingSelect' name='sort_key' value={params.sort_key} onChange={onSelectChange}>
                                                    <option disabled defaultValue value="">Sort By</option>
                                                    {options.map((data, index) => {
                                                        return (
                                                            <option key={index} value={data.value}>{data.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            {searchParams.get("sort_key") && searchParams.get("sort_key") !== "" ?
                                                <div className='my-1 '>
                                                    <select className='sortingSelect' name='sort_value' value={params.sort_value} onChange={onSelectChange}>

                                                        {sortTypes.map((data, index) => {
                                                            return (
                                                                <option key={index} value={data.value}>{data.name}</option>
                                                            )
                                                        })}
                                                    </select> </div> : null}
                                          
                                        </div> */}
                                        <div className='my-1'>  {showFilterBtn ?
                                            <button type="button" className="textClear" onClick={clearFilter}>Clear Filter</button> : null}
                                        </div>
                                    </div>
                                </div>
                                {userList && userList.length > 0 ?
                                    <>
                                        <div className="table-responsive">
                                            <table className="table dataTable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th className='sorting' scope="col">CIMB Code</th>
                                                        <th className='sorting' scope="col">Created Date</th>
                                                        <th className='sorting' scope="col">User</th>
                                                        <th scope="col">Membership Expires on</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {userList.map((data, index) => {
                                                        let updInd = index + 1
                                                        return (
                                                            <tr key={index} >
                                                                <td>
                                                                    {/* {(params.page - 1) * 20 + updInd} */}
                                                                    {((searchParams.get("page") || 1) - 1) * 20 + updInd}
                                                                </td>
                                                                <td>{data?.number}</td>
                                                                <td>{data?.createdAt ? formatDate(data?.createdAt) : "--"}</td>
                                                                <td>
                                                                    {data?.user ? <Link to={`/users/${data?.user?._id}`} className=' '>
                                                                        <span style={{ color: "#000" }}>{data?.user?.full_name}</span>
                                                                        <br />
                                                                        {data?.user?.email}
                                                                    </Link> : " -"}</td>
                                                                {/* <td>{moment(data?.metrics.chargingStart).format("lll")}</td> */}
                                                                <td>
                                                                    {data?.mebership_details && data?.mebership_details?.expire_date ? formatDate(data?.mebership_details?.expire_date) : "--"}

                                                                    <div>
                                                                        {data?.mebership_details?.membership_status === "expired" ? <span className="badge badge-small bg-danger mt-1 text-capitalized" >{data?.mebership_details?.membership_status}</span> : null}
                                                                    </div>
                                                                </td>
                                                                <td><span className={`badge ${data?.used ? "bg-success" : "bg-danger"}`}>{data?.used ? "Used" : "Unused"}</span></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div style={{ maxWidth: "500px" }}>
                                            <ResponsivePagination
                                                current={parseFloat(searchParams.get("page"))}
                                                total={count.length}
                                                onPageChange={setCurrentPage}
                                                disabled={params.page === count.length}
                                                // maxWidth={500}
                                                className={"pagination "}
                                            />

                                        </div>

                                    </> :
                                    <div className='text-center py-5'>
                                        <h3>No serial number found</h3>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Modal show={show} onHide={handleClose} centered size="md"
            // size={modelType === "delete" ? "md" : "md"}
            >
                <Modal.Body className='py-4'>
                    <div className=' mb-4'>
                        <h5>Generate Multiple Codes</h5>
                    </div>
                    <div className=' mb-4'>

                        <Form.Group >
                            <Form.Label><strong>Select Quantity*</strong></Form.Label>
                            <Form.Select name='size' value={quantity.size} onChange={handleValuechange}>

                                <option value={""} defaultValue disabled>Select </option>
                                {options3.map((value) => (
                                    <option key={value} value={value} >
                                        {value}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors && quantity.size == "" ?
                                <div className='invalid-feedback d-block'>Please select quantity</div>
                                : null}
                        </Form.Group>



                    </div>
                    <div className="text-center mt-2 ">
                        <button className='btn btn-primary  me-2' onClick={addcodeInbulk} > Update</button>
                        <button className='btn btn-outline-danger ' onClick={handleClose} > Cancel</button>
                    </div>

                </Modal.Body>
            </Modal>
        </main>

    )
}
