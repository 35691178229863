import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ColumnChart = (props) => {
    const { data, label, tooltip } = props
    // console.log("first data", data)
    const chartData = {
        labels: label,
        datasets: data
    };

    const options = {
        responsive: true,
        plugins: {
            legend: { position: "bottom", display: true },
            title: { display: false, text: "" },
            tooltip: tooltip,
        },
        elements: {
            point: {
              radius: 5,          // Default dot size
              hitRadius: 10,      // Expands hover detection area
              hoverRadius: 8,     // Increases dot size when hovered
              hoverBorderWidth: 3 // Adds a border on hover for visibility
            }
          },
        scales: {
            x: { stacked: false, grid: { display: false } },
            y: { beginAtZero: true },
        },
        barPercentage: 1.0
    };

    return <Bar data={chartData} options={options} />;
};

export default ColumnChart;
